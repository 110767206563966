import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../../components/Loader';
import Content from '../../../../../components/Content';
import ContentBox from '../../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../../components/ProcessSideContent';
import FormInput from '../../../../../components/FormInput';
import FormSelect from '../../../../../components/FormSelect';
import FormTextarea from '../../../../../components/FormTextarea';
import FormCheckbox from '../../../../../components/FormCheckbox';

import { useStateWithCallbackLazy } from 'use-state-with-callback';

// Utils
import formatPrice from '../../../../../utils/formatPrice';

function DefaultProcessesVerwaltungBenutzerCreateView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false);

	const [firstnameInput, setFirstnameInput] = useState("");
	const [lastnameInput, setLastnameInput] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [passwordInput, setPasswordInput] = useState("");
	
	const [firstnameError, setFirstnameError] = useState(false);
	const [lastnameError, setLastnameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);

	const resetAllErrors = () => {
		setFirstnameError(false);
		setLastnameError(false);
		setEmailError(false);
		setPasswordError(false);
	}

	const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    } 

	const createUser = () => {

		resetAllErrors();
		setShowLoader(true);

		/*
			REQUIRED FIELDS:
			firstnameInput
			lastnameInput
			emailInput
			passwordInput
		*/		

		// Check if email is valid. But notice: E-Mails can contain umlauts like äöü
		if (emailInput.trim() !== "") {
			if (!validateEmail(emailInput)) {
				setEmailError(true);
				setShowLoader(false);
				return;
			}
		}

		/*
			Possible errors:
			firstnameError
			lastnameError
			emailError
			passwordError
		*/

		// If all error-States are false, create customer
		if (!firstnameError && !lastnameError && !emailError && !passwordError) {
			// Create customer
			axios.post(`${process.env.REACT_APP_API_URL}/api/users`, {
				firstname: firstnameInput,
				name: lastnameInput,
				email: emailInput,
				password: passwordInput,
				password_confirmation: passwordInput,
			}).then((res) => {
				setShowLoader(false);
				if (res.data.data.firstname) {
					setShowLoader(false);
					navigate.push("/status/success")
				} else {
					setShowLoader(false);
					navigate.push("/status/error")
				}
			}).catch((err) => {
				setShowLoader(false);
				navigate.push("/status/error")
			})

		} else {
			setShowLoader(false);
			return;
		}

	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Verwalten Sie Benutzer in Ihrem Unternehmen mit Zugriff zu SalesX."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/verwaltung/benutzer");
					}}

					hasSubmitButton={true}
					submitButtonTitle="Benutzer anlegen"
					submitButtonAction={() => {
						createUser()
					}}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Benutzerdaten"
					>
						<FormInput
							label="Vorname(n)"
							value={firstnameInput}
							onChange={(e) => {
								setFirstnameInput(e.target.value);
							}}
							required
							error={firstnameError}
						/>
						<FormInput
							label="Nachname"
							value={lastnameInput}
							onChange={(e) => {
								setLastnameInput(e.target.value);
							}}
							required
							error={lastnameError}
						/>
						<FormInput
							label="E-Mail"
							value={emailInput}
							onChange={(e) => {
								setEmailInput(e.target.value);
							}}
							required
							error={emailError}
						/>
						<FormInput
							label="Passwort"
							value={passwordInput}
							onChange={(e) => {
								setPasswordInput(e.target.value);
							}}
							required
							error={passwordError}
							type="password"
						/>
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungBenutzerCreateView;