const formatPrice = (input) => {
	let value = input;
	value = value.toString();
	value = value.replace('€', '');
	value = value.replace(' ', '');
	
	if (value !== "" && !(value).includes('NaN')) {
		value = value.replace(' EUR', '');
		value = value.replace('&nbsp;', ' ');

		// Create our number formatter.
		var formatter = new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
		});

		value = formatter.format((value).replace(',', '.'));

		if (value.includes("NaN")) {
			value = "0,00 €";
		}

		return value;
	}

	return "0,00 €";
}

export default formatPrice;