import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../../components/Loader';
import Content from '../../../../../components/Content';
import ContentBox from '../../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../../components/ProcessSideContent';
import FormInput from '../../../../../components/FormInput';
import FormSelect from '../../../../../components/FormSelect';
import FormTextarea from '../../../../../components/FormTextarea';
import FormCheckbox from '../../../../../components/FormCheckbox';

import { useStateWithCallbackLazy } from 'use-state-with-callback';

// Utils
import formatPrice from '../../../../../utils/formatPrice';

function DefaultProcessesVerwaltungBenutzerDetailsView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);
	const [reloadKey, setReloadKey] = useState(0);
	const [editMode, setEditMode] = useState(false);
	const { userId } = useParams();
	const [finishedPercent, setFinishedPercent] = useState(0);
	const [userIdInt, setUserIdInt] = useState(0);

	const [userPermissions, setUserPermissions] = useState([]);

	const [firstnameInput, setFirstnameInput] = useState("");
	const [lastnameInput, setLastnameInput] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [passwordInput, setPasswordInput] = useState("");
	
	const [firstnameError, setFirstnameError] = useState(false);
	const [lastnameError, setLastnameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);

	useEffect(() => {
		if (!userId) {
			navigate.push("/default-processes/verwaltung/benutzer");
		} else {
			axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}`)
				.then((res) => {
					console.log(res.data.data.name);
					if (res.data?.data?.name && res.data?.data?.email) {
						setFirstnameInput(res.data?.data?.firstname);
						setLastnameInput(res.data?.data?.name);
						setEmailInput(res.data?.data?.email);

						setUserIdInt(res.data?.data?.id);

						axios.get(`${process.env.REACT_APP_API_URL}/api/permissions/${res.data?.data?.id}`)
							.then((res) => {
								setShowLoader(false);
								setUserPermissions(res.data.data);
							})
							.catch((err) => {
								navigate.push("/status/error");
							})

					} else {
						navigate.push("/status/error");
					}
				})
				.catch((err) => {
					navigate.push("/status/error");
				})
		}
	}, [userId, reloadKey])

	return (
		<>
			{showLoader && (
				<Loader text={`Prozess wird ausgeführt... ${(finishedPercent > 0) ? finishedPercent + " %" : ""}`} />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Verwalten Sie Benutzer in Ihrem Unternehmen mit Zugriff zu SalesX."

					hasCancelButton={true}
					cancelButtonAction={() => {
						if (!editMode) {
							navigate.push("/default-processes/verwaltung/benutzer");
						} else {
							setShowLoader(true);
							setEditMode(false);
							setReloadKey(reloadKey + 1);
						}
					}}

					actionButtons={editMode ? [] : [
						{
							title: "Benutzer bearbeiten",
							action: () => {
								setEditMode(true)
							}
						}
					]}

					hasSubmitButton={true}
					submitButtonTitle={(editMode ? "Benutzer speichern" : "Vorgang beenden")}
					submitButtonAction={() => {
						if (editMode) {
							//!

							// Check if all inputs are filled
							if (firstnameInput === "" || firstnameInput === null) {
								console.log("Firstname is empty: " + firstnameInput);
								setFirstnameError(true);
							}
							if (lastnameInput === "" || lastnameInput === null) {
								console.log("Lastname is empty");
								setLastnameError(true);
							}
							if (emailInput === "" || emailInput === null) {
								console.log("Email is empty");
								setEmailError(true);
							}

							if (firstnameInput !== "" && firstnameInput !== null && lastnameInput !== "" && lastnameInput !== null && emailInput !== "" && emailInput !== null) {

								setShowLoader(true);

								let data = {
									firstname: firstnameInput,
									name: lastnameInput,
									email: emailInput,
									password: passwordInput
								}

								axios.put(`${process.env.REACT_APP_API_URL}/api/users/${userId}`, data)
									.then((res) => {

										let finishedPermissionUpdates = false
										
										userPermissions.forEach(permission => {
											
											axios.put(`${process.env.REACT_APP_API_URL}/api/permissions/${userId}`, {
												permission_key: permission.permission_key,
												permission_value: permission.permission_value
											})
												.then((res) => {
													console.log("Finished updating permission: " + permission.permission_key + " to " + permission.permission_value);
													console.log("Updating " + (userPermissions.indexOf(permission) + 1) + " of " + userPermissions.length);

													setFinishedPercent(Math.round((userPermissions.indexOf(permission) + 1) / userPermissions.length * 100));

													if (userPermissions.indexOf(permission) === userPermissions.length - 1) {
														console.log("Finished updating all permissions");
														finishedPermissionUpdates = true;
														setShowLoader(false);
														setEditMode(false);
														setReloadKey(reloadKey + 1);			
													}
												})
												.catch((err) => {
													console.log(err);
													//finishedPermissionUpdates = true;
												});

										});
									})

							}

						} else {
							navigate.push("/default-processes/verwaltung/benutzer");
						}
					}}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Benutzerdaten"
					>
						<FormInput
							label="Vorname(n)"
							value={firstnameInput}
							onChange={(e) => {
								setFirstnameInput(e.target.value);
							}}
							required
							error={firstnameError}
							disabled={!editMode}
						/>
						<FormInput
							label="Nachname"
							value={lastnameInput}
							onChange={(e) => {
								setLastnameInput(e.target.value);
							}}
							required
							error={lastnameError}
							disabled={!editMode}
						/>
						<FormInput
							label="E-Mail"
							value={emailInput}
							onChange={(e) => {
								setEmailInput(e.target.value);
							}}
							required
							error={emailError}
							disabled={!editMode}
						/>
						{editMode && (
							<FormInput
								label="Neues Passwort"
								value={passwordInput}
								onChange={(e) => {
									setPasswordInput(e.target.value);
								}}
								error={passwordError}
								type="password"
								disabled={!editMode}
							/>
						)}
					</ContentBox>
					
					<ContentBox
						title="Berechtigungen"
					>
						<div className="DefaultProcessesVerwaltungBenutzerDetailsView-Permissions">
							{userPermissions.map((permission, index) => {
								return (
									<FormCheckbox
										key={index}
										label={permission.permission_key}
										checked={permission.permission_value === 1 ? true : false}
										onChange={(e) => {
											// e = boolean
											// Set permission value in array
											let newPermissions = [...userPermissions];
											newPermissions[index].permission_value = e ? 1 : 0;

											setUserPermissions(newPermissions);
										}}
										text="Freigegeben"
										disabled={!editMode}
									/>
								)
							})}
						</div>
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungBenutzerDetailsView