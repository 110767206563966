import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Content from '../../../../components/Content';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import ContentBox from '../../../../components/ContentBox';
import FormInput from '../../../../components/FormInput';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import axios from 'axios';

function DefaultProcessesVerwaltungJahresabschlussView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false)
	const [year, setYear] = useState(new Date().getFullYear())
	const [yearError, setYearError] = useState(false)
	const [showWarningModal, setShowWarningModal] = useState(false)

	const initiateYearlyClose = () => {
		setShowLoader(true);
		setShowWarningModal(false);
		axios.get(`${process.env.REACT_APP_API_URL}/api/configuration/check-open-bills/${year}`)
		.then((res) => {
			if (res.data.status === "success") {
				if (res.data.open_bills > 0) {
					setShowLoader(false);
					setShowWarningModal(true);
					return;
				} else {
					doYearlyClose();
				}
			} else {
				setShowLoader(false);
				navigate.push("/status/error")
			}
		})
	}

	const doYearlyClose = (force = false) => {
		setShowLoader(true);
		axios.post(`${process.env.REACT_APP_API_URL}/api/configuration/annual-closing`, {
			year: year
		}).then((res) => {
			if (res.data.status === "success") {
				setShowLoader(false);

				axios.get(`${process.env.REACT_APP_API_URL}/api/archive/5/${res.data.data.archive_file_id}`, {
					responseType: 'blob'
				})
				.then((res) => {
					setShowLoader(false);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', "Jahresabschluss-" + year + ".pdf");
					document.body.appendChild(link);
					link.click();
				})

				navigate.push(`/status/success`);
			} else {
				setShowLoader(false);
				navigate.push("/status/error")
			}
		}).catch((err) => {
			setShowLoader(false);
			navigate.push("/status/error")
		})
	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Bereiten Sie Ihr Unternehmen auf das neue Steuerjahr vor."
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/default-processes/verwaltung");
					}}

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/verwaltung");
					}}

					actionButtons={[
						{
							title: "Jahresabschluss durchführen",
							action: () => {
								if (yearError) {
									return;
								}
								initiateYearlyClose();
		
							}
						}
					]}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Jahresabschluss"
					>
						<FormInput
							label="Abschlussjahr"
							type="text"
							required
							value={year}
							onChange={(e) => {setYearError(false);setYear(e.target.value.trim())}}
							inputblur={(e) => {
								// Check if year is valid
								if (e.target.value.length !== 4) {
									setYearError(true);
									return;
								} else {
									setYearError(false)
								}

								// Check if year contains only numbers
								if (isNaN(e.target.value)) {
									setYearError(true);
									return;
								} else {
									setYearError(false)
								}

								// Check if year is in future
								if (e.target.value > new Date().getFullYear()) {
									setYearError(true);
									return;
								} else {
									setYearError(false)
								}
							}}
							error={yearError}
						/>
					</ContentBox>
				</ProcessSideContent>
			</Content>

			{showWarningModal && (
				<Modal
					title="Es sind noch offene Rechnungen vorhanden!"
					isClosable={true}
					closeAction={() => {
						setShowWarningModal(false);
					}}

					actionButtons={[
						{
							title: "Jahresabschluss durchführen",
							action: () => {
								doYearlyClose(true)
							}
						}
					]}
				>
					<p>
						Es sind noch offene Rechnungen vorhanden! Möchten Sie dennoch einen Jahresabschluss durchführen? Diese Buchungen werden nicht in den Jahresabschluss übernommen.
					</p>
				</Modal>
			)}
		</>
	);
}

export default DefaultProcessesVerwaltungJahresabschlussView;