import React, { useState, useRef, useId } from 'react';
import { useEffect } from 'react';

// Styles
import './styles.scss';

function FormInput(props) {
	
	const htmlForId = useId();

	const maskDate = (event) => {
		let v = (event.target.value).replace(/\D/g,'').slice(0, 10);
		let returner = v;
		if (v.length >= 4) {
			returner = `${v.slice(0,2)}.${v.slice(2,4)}.${v.slice(4)}`;
		}
		else if (v.length >= 2) {
			returner = `${v.slice(0,2)}.${v.slice(2)}`;
		}
		event.target.value = returner.slice(0,10);
		return event;
	}
	
	const maskPrice = (event) => {
		let value = event.target.value;
		value = value.replaceAll('€', '');
		value = value.replaceAll(' ', '');
		
		if (value !== "" && !(value).includes('NaN')) {
			value = value.replaceAll(' EUR', '');
			value = value.replaceAll(' €', '');
			value = value.replaceAll('.', '');
			value = value.replaceAll(',', '.');
			value = value.replaceAll('&nbsp;', ' ');

			// Create our number formatter.
			var formatter = new Intl.NumberFormat('de-DE', {
				style: 'currency',
				currency: 'EUR',
			});

			event.target.value = formatter.format((value).replaceAll(',', '.'));

			if (formatter.format((value).replaceAll(',', '.')).includes("NaN")) {
				event.target.value = "0,00 €";
			}

			return event;
		}

		return event;

	}
	return (
		<div
			className={`FormInput${props.minimal ? ' FormInput--minimal' : ''}`}
		>
			{props.label && (
				<label htmlFor={htmlForId} className="FormInput-Label">{props.label}:</label>
			)}
			<input
				value={props.value}
				onKeyDown={(event) => {
					if (props.formatdate) {
						let key = event.keyCode || event.charCode;
						let v = event.target.value;
						if(key === 8 || key === 46) {
							// Get last character of the string
							let lastChar = v.slice(-1);
							if (lastChar === '.') {
								v = v.slice(0, -1);
								event.target.value = v;
							}
							return;
						}
					}
				}}
				onChange={(event) => {
					if (props.formatdate) {
						props.customOnChange(maskDate(event))
					} else {
						props.customOnChange(event)

						if (props.autocomplete) {
							// Search in the autocomplete list for the value
							let found = false;
							props.autocompleteItems.forEach((item) => {
								if (item.toLowerCase().includes(event.target.value.toLowerCase())) {
									found = true;
								}
							})
						}

					}
				}}

				onBlur={(event) => {
					if (props.formatprice) {
						props.onChange(maskPrice(event))
					}

					if (props.inputblur) {
						props.inputblur(event)
					}
				}}

				autoComplete={props.autocomplete ? "off" : "on"}

				required={props.required}
				placeholder={props.placeholder ? props.placeholder : (props.required ? "Bitte eingeben" : "Optional")}
			
				disabled={props.disabled}
				
				type={props.type ? props.type : "text"}
				className={`FormInput-Input${(!props.label) ? ' FormInput-Input--noLabel' : ''} ${props.error && 'FormInput-Input--error'}`}
				id={htmlForId}

				{...props}
			/>
			{props.disabled && (
				<span className="material-symbols-rounded FormInput-DisabledIcon">
					lock
				</span>
			)}
		</div>
	);
}

export default FormInput;