import React, {useEffect,useState} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import {setAuthToken} from "../../utils/setAuthToken";

// Styles
import './styles.scss';

// Components
import Loader from '../../components/Loader';
import ContentBox from '../../components/ContentBox';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';

function LoginView() {

	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [emailForm, setEmailForm] = useState("");
	const [passwordForm, setPasswordForm] = useState("");
	
	useEffect(() => {
		setIsLoading(true);

		// Check if user is logged in
		const token = localStorage.getItem("token");
		if (token) {
			setAuthToken(token);
			setIsLoading(false);

			axios.get(`${process.env.REACT_APP_API_URL}/api/me`).then((res) => {
				console.log(res);
				if (res.data.data) {

					// Check if the user has a role
					localStorage.setItem("data", JSON.stringify(res.data.data));

				}
			})
			.catch((error) => {
				
			})
		} else {
			setIsLoading(false);
			setEmailForm("");
			setPasswordForm("");
		}

	}, [])

	const doLogin = () => {

        setIsLoading(true)

        axios.post(`${process.env.REACT_APP_API_URL}/oauth/token`, {
            grant_type: 'password',
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: emailForm,
            password: passwordForm
        })
        .then(function (response) {
            if (response.status === 200) {
                let accessToken = response.data.access_token;
                let refreshToken = response.data.refresh_token;
                let expiresIn = (response.data.expires_in / 60);

                axios.get(`${process.env.REACT_APP_API_URL}/api/me`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then((res) => {
                    setIsLoading(false)
                    if (res.data.data) {
						localStorage.setItem("token", accessToken);
						localStorage.setItem("data", JSON.stringify(res.data.data));
						setAuthToken(accessToken);
						window.location.href = '/'
                    } else {
						setHasError(true)
						setPasswordForm("");
					}
                })
                .catch((error) => {
                    setIsLoading(false)
					setHasError(true)
					setPasswordForm("");
                })

                // Get user data

            } else {
                setIsLoading(false)
				setHasError(true)
				setPasswordForm("");
            }
        })
        .catch(function (error) {
            setIsLoading(false)
            setHasError(true)
			setPasswordForm("");
        });

	}

	return (
		<div className="Login-Content">
			{isLoading && (
				<Loader />
			)}
			<ContentBox title="Bei SalesX anmelden">

				{hasError && (
					<div className="Login-Error">
						<span>Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Zugangsdaten.</span>
					</div>
				)}

				<FormInput
					label="E-Mail-Adresse"
					type="email"
					value={emailForm}
					onChange={(e) => {
						setEmailForm(e.target.value);
					}}
					required
				/>
				<FormInput
					label="Passwort"
					type="password"
					value={passwordForm}
					onChange={(e) => {
						setPasswordForm(e.target.value);
					}}
					required
				/>

				<div className='Login-Form-Actions'>
					<Button onClick={doLogin}>Anmelden</Button>
				</div>
			</ContentBox>
		</div>
	);
}

export default LoginView;