import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import LinkBox from '../../../components/LinkBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';

function DefaultProcessesProdukteView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);
	const [products, setProducts] = useState([]);

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/products`).then((res) => {
			setProducts(res.data);
			setShowLoader(false);
		})
	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="produkte-1.jpg"
					title="Produkte verwalten"
					text="Verwalten Sie Ihre Produkte, Workflows und Medien."

					hasCancelButton={false}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/")
					}}

					actionButtons={[
						{
							title: "Produkt anlegen",
							action: () => {
								navigate.push("/default-processes/produkte/create");
							}
						}
					]}
				/>

				<ProcessSideContent>
					{products.length === 0 && (
						<div className="Empty-List">
							<h3>Es sind keine Produkte vorhanden.</h3>
							<i>DP_PRODUKTE_VIEW_EMPTY</i>
						</div>
					)}
					{
						products.map((product, index) => (
							<LinkBox
								key={index}
								link={`/default-processes/produkte/${product.id}/edit`}
								title={`#${product.product_sku} - ${product.product_name}`}
								text={product.product_description}
							/>
						))
					}
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesProdukteView;