import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import LinkBox from '../../../components/LinkBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';
import formatPrice from '../../../utils/formatPrice';
import formatDate from '../../../utils/formatDate';

function DefaultProcessesInterneKontenView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);
	const [internalAccounts, setInternalAccounts] = useState([]);

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/internal-accounts`).then((res) => {
			console.log(res.data)
			setInternalAccounts(res.data);
			setShowLoader(false);
		})
	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="computers-1.jpg"
					title="Interne Konten verwalten"
					text="Verwalten Sie Ihre internen Konten, Transaktionen &amp; Umsätze."

					hasCancelButton={false}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/")
					}}
				/>

				<ProcessSideContent>
					{internalAccounts.map((internalAccount, i) =>
						<LinkBox
							disabled={(internalAccount.account_number === "-1")}
							key={i}
							link={`/default-processes/internal-accounts/${internalAccount.id}/edit`}
							title={`${internalAccount.account_number !== "-1" ? internalAccount.account_number + "; " : ""}${internalAccount.account_name}`}
							text={`${internalAccount.account_description} (${internalAccount.account_type}${internalAccount.parent_name ? (`; Elem. in ${internalAccount.parent_name}`) : ''})`}
						/>
					)}
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesInterneKontenView;