import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../components/Loader';
import Content from '../../../../components/Content';
import ContentBox from '../../../../components/ContentBox';
import Modal from '../../../../components/Modal';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import FormInput from '../../../../components/FormInput';

function DefaultProcessesArchivFolderView() {

	let navigate = useHistory();
	let {folderId} = useParams();
	const [showLoader, setShowLoader] = useState(true);
	const [folderName, setFolderName] = useState("");
	const [archiveFiles, setArchiveFiles] = useState([]);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [archiveFileNameValue, setArchiveFileNameValue] = useState("");
	const [archiveFileDescriptionValue, setArchiveFileDescriptionValue] = useState("");
	const [archiveFileNameError, setArchiveFileNameError] = useState(false)
	const [archiveFileDocumentValue, setArchiveFileDocumentValue] = useState(null)
	const [archiveFileDocumentError, setArchiveFileDocumentError] = useState(false)

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/archive/${folderId}`).then((res) => {
			console.log(res.data)
			setFolderName(res.data.data.folder_name);
			setArchiveFiles(res.data.data.archive_files);
			setShowLoader(false);
		})
	}, [folderId])

	const handleFileSelect = (event) => {
		setArchiveFileDocumentError(false)
		setArchiveFileDocumentValue(event.target.files[0])
	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="archiv-1.jpeg"
					title="Dokumentenarchiv"
					text="Verwalten Sie Ihre Dokumente, Dateien in Ihrem zentralen SalesX-Archiv."

					hasCancelButton={false}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/default-processes/archiv")
					}}

					actionButtons={[
						{
							title: "Dokument hochladen",
							action: () => {
								setShowCreateModal(true);
							}
						}
					]}
				/>

				<ProcessSideContent>
					<ContentBox title={`Dateien in "${folderName}"`}>
						<ul className="FolderList">
							{archiveFiles.map((archiveFile, i) =>
								<li
									key={i}
								>
									<a
										href="#null"
										onClick={(event) => {
											event.preventDefault()
											setShowLoader(true);
											console.log(archiveFile)
											axios.get(`${process.env.REACT_APP_API_URL}/api/archive/${folderId}/${archiveFile.id}`, {
												responseType: 'blob'
											})
											.then((res) => {
												setShowLoader(false);

												const url = window.URL.createObjectURL(new Blob([res.data]));
												const link = document.createElement('a');
												link.href = url;
												link.setAttribute('download', archiveFile.document_path.replace("archive/", "")); //or any other extension
												document.body.appendChild(link);
												link.click();											
											})
											.catch((err) => {
												setShowLoader(false);
												navigate.push("/status/error");		
											})
										}}
									>
										<span>{`#${archiveFile.id} ${archiveFile.document_name}`} {archiveFile.document_description !== null ? (<><br /><i>{archiveFile.document_description}</i></>) : ""}</span>

										<span className="material-symbols-rounded">
											chevron_right
										</span>
									</a>
								</li>
							)}
						</ul>
					</ContentBox>
				</ProcessSideContent>
			</Content>

			{showCreateModal && (
				<Modal
					title="Dokument hochladen"
					isClosable={true}
					closeAction={() => {
						setShowCreateModal(false);
					}}

					actionButtons={[
						{
							title: "Anlegen",
							action: () => {
								if (archiveFileNameValue.trim().length === 0) {
									setArchiveFileNameValue(true);
									return;
								} else {
									setArchiveFileNameError(false);
								}

								setShowLoader(true);

								const formData = new FormData();
    							formData.append("file_value", archiveFileDocumentValue);
    							formData.append("file_name", archiveFileNameValue);
    							formData.append("file_description", archiveFileDescriptionValue);

								axios.post(`${process.env.REACT_APP_API_URL}/api/archive/${folderId}`, formData, {
									headers: {
										'Content-Type': 'multipart/form-data'
									}
								}).then((res) => {
									setShowLoader(false);
									if (res.data.status === "success") {
										setShowLoader(false);
										navigate.push("/status/success");
									} else {
										setShowLoader(false);
										navigate.push("/status/error");
									}
								})
							}
						}
					]}
				>
					<p>Gebe einen Namen für deinen neuen Ordner und eine Beschreibung (optional) an.</p>

					<FormInput
						label="Dateiname"
						type="text"
						required
						value={archiveFileNameValue}
						onChange={(e) => {setArchiveFileNameValue(e.target.value)}}
						error={archiveFileNameError}
					/>
					<FormInput
						label="Datei auswählen"
						type="file"
						required
						onChange={handleFileSelect}
						error={archiveFileDocumentError}
					/>
					<FormInput
						label="Beschreibung"
						type="text"
						value={archiveFileDescriptionValue}
						onChange={(e) => {setArchiveFileDescriptionValue(e.target.value)}}
					/>
				</Modal>
			)}
		</>
	);
}

export default DefaultProcessesArchivFolderView;