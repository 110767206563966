import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Content from '../../../components/Content';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';
import ContentBox from '../../../components/ContentBox';
import FormInput from '../../../components/FormInput';
import Loader from '../../../components/Loader';
import FormSelect from '../../../components/FormSelect';
import FormCheckbox from '../../../components/FormCheckbox';
import axios from 'axios';

function DefaultProcessesVerwaltungView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false)
	const [cfgCompanyNameForm, setCfgCompanyNameForm] = useState("")
	const [cfgCompanyOwnerForm, setCfgCompanyOwnerForm] = useState("")
	const [cfgCompanyAddressForm, setCfgCompanyAddressForm] = useState("")
	const [cfgCompanyCityForm, setCfgCompanyCityForm] = useState("")
	const [cfgCompanyZipCodeForm, setCfgCompanyZipCodeForm] = useState("")
	const [cfgCompanyVatIdForm, setCfgCompanyVatIdForm] = useState("")
	
	const [cfgContactMailForm, setCfgContactMailForm] = useState("")
	const [cfgContactPhoneForm, setCfgContactPhoneForm] = useState("")
	const [cfgContactWebsiteForm, setCfgContactWebsiteForm] = useState("")
	
	const [cfgBankRecipientForm, setCfgBankRecipientForm] = useState("")
	const [cfgBankIbanForm, setCfgBankIbanForm] = useState("")
	const [cfgBankBicForm, setCfgBankBicForm] = useState("")
	const [cfgBankNameForm, setCfgBankNameForm] = useState("")
	
	const [cfgLetterHeadForm, setCfgLetterHeadForm] = useState("")
	const [cfgLogoUrl, setCfgLogoUrl] = useState("")
	const [cfgInvoiceNumberFormat, setCfgInvoiceNumberFormat] = useState("")
	const [cfgAutoCreateProducts, setCfgAutoCreateProducts] = useState(true)

	const validateIban = (iban) => {
		setShowLoader(true);
		setCfgBankBicForm("");
		setCfgBankNameForm("");
		axios.get(`https://openiban.com/validate/${iban}?getBIC=true`).then((res) => {
			setShowLoader(false);
			if (res.data) {
				if (res.data.valid) {
					setCfgBankNameForm(res.data.bankData.name)
					setCfgBankBicForm(res.data.bankData.bic)
				}
			}
		}).catch((err) => {
			setShowLoader(false);
		})
		return;
	}

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/configuration`)
			.then((res) => {
				setShowLoader(false);
				console.log(res.data)
				res.data.forEach(configItem => {
					if (configItem.configuration_name === "COMPANY_NAME") {
						setCfgCompanyNameForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_OWNER") {
						setCfgCompanyOwnerForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_ADDRESS") {
						setCfgCompanyAddressForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_CITY") {
						setCfgCompanyCityForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_ZIP") {
						setCfgCompanyZipCodeForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_VAT_ID") {
						setCfgCompanyVatIdForm(configItem.configuration_value)
					}

					if (configItem.configuration_name === "COMPANY_CONTACT_MAIL") {
						setCfgContactMailForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_CONTACT_PHONE") {
						setCfgContactPhoneForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_CONTACT_WEBSITE") {
						setCfgContactWebsiteForm(configItem.configuration_value)
					}

					if (configItem.configuration_name === "COMPANY_BANK_RECIPIENT") {
						setCfgBankRecipientForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_BANK_IBAN") {
						setCfgBankIbanForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_BANK_BIC") {
						setCfgBankBicForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_BANK_NAME") {
						setCfgBankNameForm(configItem.configuration_value)
					}

					if (configItem.configuration_name === "COMPANY_LETTER_HEAD") {
						setCfgLetterHeadForm(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_LOGO_URL") {
						setCfgLogoUrl(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_INVOICE_NUMBER_FORMAT") {
						setCfgInvoiceNumberFormat(configItem.configuration_value)
					}
					if (configItem.configuration_name === "COMPANY_AUTO_CREATE_PRODUCTS") {
						setCfgAutoCreateProducts(configItem.configuration_value === "true" ? true : false)
					}
				});
			})
	}, [])

	const handleSubmit = () => {
		setShowLoader(true);
		axios.put(`${process.env.REACT_APP_API_URL}/api/configuration`, {
			COMPANY_NAME: cfgCompanyNameForm,
			COMPANY_OWNER: cfgCompanyOwnerForm,
			COMPANY_ADDRESS: cfgCompanyAddressForm,
			COMPANY_CITY: cfgCompanyCityForm,
			COMPANY_ZIP: cfgCompanyZipCodeForm,
			COMPANY_VAT_ID: cfgCompanyVatIdForm,
			COMPANY_CONTACT_MAIL: cfgContactMailForm,
			COMPANY_CONTACT_PHONE: cfgContactPhoneForm,
			COMPANY_CONTACT_WEBSITE: cfgContactWebsiteForm,
			COMPANY_BANK_RECIPIENT: cfgBankRecipientForm,
			COMPANY_BANK_IBAN: cfgBankIbanForm,
			COMPANY_BANK_BIC: cfgBankBicForm,
			COMPANY_BANK_NAME: cfgBankNameForm,
			COMPANY_LETTER_HEAD: cfgLetterHeadForm,
			COMPANY_LOGO_URL: cfgLogoUrl,
			COMPANY_INVOICE_NUMBER_FORMAT: cfgInvoiceNumberFormat,
			COMPANY_AUTO_CREATE_PRODUCTS: cfgAutoCreateProducts
		})
		.then((res) => {
			setShowLoader(false);
			if (res.data.status === "success") {
				setShowLoader(false);
				navigate.push("/status/success")
			} else {
				setShowLoader(false);
				navigate.push("/status/error")
			}
		}).catch((err) => {
			setShowLoader(false);
			navigate.push("/status/error")
		})
	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Verwalten Sie Ihr Unternehmen und Ihre SalesX-Konfiguration."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/");
					}}
					
					hasSubmitButton={true}
					submitButtonTitle="Speichern"
					submitButtonAction={() => {
						handleSubmit();
					}}

					actionButtons={[
						{
							title: "Benutzerverwaltung",
							action: () => {
								navigate.push("/default-processes/verwaltung/benutzer")
							}
						},
						{
							title: "Jahresabschluss",
							action: () => {
								navigate.push("/default-processes/verwaltung/jahresabschluss")
							}
						}
					]}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Unternehmensverwaltung"
					>

						<FormInput
							label="(jur.) Unternehmensname"
							value={cfgCompanyNameForm}
							onChange={(e) => {
								setCfgCompanyNameForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Inhaber"
							value={cfgCompanyOwnerForm}
							onChange={(e) => {
								setCfgCompanyOwnerForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Adresse"
							value={cfgCompanyAddressForm}
							onChange={(e) => {
								setCfgCompanyAddressForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Stadt"
							value={cfgCompanyCityForm}
							onChange={(e) => {
								setCfgCompanyCityForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Postleitzahl"
							value={cfgCompanyZipCodeForm}
							onChange={(e) => {
								setCfgCompanyZipCodeForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Umsatzsteuer-ID"
							value={cfgCompanyVatIdForm}
							onChange={(e) => {
								setCfgCompanyVatIdForm(e.target.value);
							}}
							required
						/>

					</ContentBox>

					<ContentBox
						title="Kontaktdaten"
					>

						<FormInput
							label="E-Mail"
							value={cfgContactMailForm}
							onChange={(e) => {
								setCfgContactMailForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Telefonnummer"
							value={cfgContactPhoneForm}
							onChange={(e) => {
								setCfgContactPhoneForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Webseite"
							value={cfgContactWebsiteForm}
							onChange={(e) => {
								setCfgContactWebsiteForm(e.target.value);
							}}
							required
						/>

					</ContentBox>

					<ContentBox
						title="Bankverbindung"
					>

						<FormInput
							label="Empfängername"
							value={cfgBankRecipientForm}
							onChange={(e) => {
								setCfgBankRecipientForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="IBAN"
							value={cfgBankIbanForm}
							onChange={(e) => {
								setCfgBankIbanForm(e.target.value.toUpperCase());
							}}
							onBlur={(e) => {
								validateIban(e.target.value);
							}}
							required
						/>

						<FormInput
							label="BIC"
							value={cfgBankBicForm}
							onChange={(e) => {
								setCfgBankBicForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Bankname"
							value={cfgBankNameForm}
							onChange={(e) => {
								setCfgBankNameForm(e.target.value);
							}}
							required
						/>

					</ContentBox>

					<ContentBox
						title="Sonstige Einstellungen"
					>

						<FormInput
							label="Briefkopfzeile"
							value={cfgLetterHeadForm}
							onChange={(e) => {
								setCfgLetterHeadForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Logo URL"
							value={cfgLogoUrl}
							onChange={(e) => {
								setCfgLogoUrl(e.target.value);
							}}
							required
						/>

						<FormSelect
							label="Nummernkreise"
							value={cfgInvoiceNumberFormat}
							onChange={(e) => {
								setCfgInvoiceNumberFormat(e.target.value)
							}}
							options={[
								{
									label: "Erhöhend (z. B. RE1, RE2, RE3, ...)",
									value: "increasing"
								},
								{
									label: "Formatiert (RE099«JAHR»«MONAT»«NUMMER»)",
									value: "formatted"
								}
							]}
							required
						/>

						<FormCheckbox
							label="Produktanlage"
							checked={cfgAutoCreateProducts}
							onChange={(e) => {
								setCfgAutoCreateProducts(e);
							}}
							text="Automatisch Produkte anlegen, wenn diese in einer Rechnung verwendet werden, aber noch nicht existieren."
						/>

					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungView;