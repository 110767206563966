import React from 'react';

// Styles
import './styles.scss';

function ProcessSideContent(props) {
	return (
		<div className="ProcessSideContent">{props.children}</div>
	);
}

export default ProcessSideContent;