import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import LinkBox from '../../../components/LinkBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';

function DefaultProcessesKundenView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);
	const [customers, setCustomers] = useState([]);

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/customers`).then((res) => {
			setCustomers(res.data);
			setShowLoader(false);
		})
	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="customers-1.jpg"
					title="Kunden verwalten"
					text="Verwalten Sie Ihre Kunden, Adressen und Kommunikationsdaten."

					hasCancelButton={false}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/")
					}}

					actionButtons={[
						{
							title: "Kunde anlegen",
							action: () => {
								navigate.push("/default-processes/kunden/create");
							}
						}
					]}
				/>

				<ProcessSideContent>
					{customers.length === 0 && (
						<div className="Empty-List">
							<h3>Es sind keine Kunden vorhanden.</h3>
							<i>DP_KUNDEN_VIEW_EMPTY</i>
						</div>
					)}
					{
						customers.map((customer, index) => (
							<LinkBox
								key={index}
								link={`/default-processes/kunden/${customer.id}/edit`}
								title={`#${customer.customer_number}, ${customer.company_name === "" ? (customer.firstname + " " + customer.lastname) : (customer.company_name + ", " + customer.firstname + " " + customer.lastname)}`}
								text={
									customer.address_line_1
									+ (customer.address_line_2 === "" ? "" : (", " + customer.address_line_2))
									+ "<br>" + customer.zip + " " + customer.city
								}
							/>
						))
					}
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesKundenView;