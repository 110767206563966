import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import LinkBox from '../../../components/LinkBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';

function DefaultProcessesDeveloperView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setShowLoader(false);
		}, 1500)
	})

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Entwickler-Modus"
					text="Konfigurieren und testen Sie Ihre Anwendungen, Prozesse und Bedienelemente."

					hasCancelButton={false}

					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/")
					}}
				/>

				<ProcessSideContent>
					<LinkBox
						link="/default-processes/developer/forms"
						title="Formulare"
						text="Testen Sie Formulare, Eingabefelder und Buttons."
					/>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesDeveloperView;