import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Content from '../../../../components/Content';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import ContentBox from '../../../../components/ContentBox';
import FormInput from '../../../../components/FormInput';
import Loader from '../../../../components/Loader';
import FormSelect from '../../../../components/FormSelect';
import axios from 'axios';

function DefaultProcessesVerwaltungBenutzerView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false)
	const [usersList, setUsersList] = useState([])

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/users`)
			.then((res) => {
				setShowLoader(false);
				console.log(res.data)
				setUsersList(res.data.data)
			})
	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Verwalten Sie Benutzer in Ihrem Unternehmen mit Zugriff zu SalesX."
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/default-processes/verwaltung");
					}}

					actionButtons={[
						{
							title: "Benutzer anlegen",
							action: () => {
								navigate.push("/default-processes/verwaltung/benutzer/create")
							}
						},
						{
							title: "Berechtigungen anlegen",
							action: () => {
								navigate.push("/default-processes/verwaltung/benutzer/create-permission")
							}
						}
					]}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Unternehmensverwaltung"
					>
						{usersList.map((user, index) => (
							<ContentBox
								onClick={() => {	
									navigate.push(`/default-processes/verwaltung/benutzer/${user.uuid}`)
								}}
								style={{
									cursor: "pointer",
									marginBottom: "10px"
								}}
								key={index}
								title={(user.firstname !== null ? user.firstname + " " : '') + (user.name !== null && user.name)}
							>
								<p>
									E-Mail: {user.email}
								</p>
							</ContentBox>
						))}
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungBenutzerView;