import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {setAuthToken} from "../../utils/setAuthToken";

// Styles
import './styles.scss';

// Components
import Loader from '../../components/Loader';
import ContentBox from '../../components/ContentBox';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';

function SystemInformationView(props) {
	
	const navigate = useHistory();

	const getCookie = (cname) => {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for(let i = 0; i <ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	const getCookieMap = () => {
		// Cookies are generally separated by a "; "
		// https://stackoverflow.com/a/4843598/2968465
		const cookieList = document.cookie.split('; ');
	  
		// A key-value pair in the cookie list is separated by a "="
		// We pass a function to cookieList.map that will return
		// an array of tuples, like [key, value]
		const cookieToObjEntry = cookie => cookie.split('=')
		const cookieEntries = cookieList.map(cookieToObjEntry)
	  
		// Such an array can be passed to Object.fromEntries to
		// obtain an object with all cookie key-value pairs as
		// the keys and values of an object
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
		return Object.fromEntries(cookieEntries)
	  
		// So, for a cookies stored as "c1=v1; c2=v2", you'll get
		// an object like `{c1: v1, c2: v2}`
	}	  

	return (
		<div className="SystemInformation-Content">
			<ContentBox>
				<span className="material-symbols-rounded">
					info
				</span>

				<h1>System-Informationen</h1>
				<br />
				<br />

				<table>
					<tbody>
						<tr>
							<td><b>API-URL:</b></td>
							<td>{process.env.REACT_APP_API_URL}</td>
						</tr>
						<tr>
							<td><b>Client-ID:</b></td>
							<td>{process.env.REACT_APP_CLIENT_ID}</td>
						</tr>
						<tr>
							<td><b>Client-Secret:</b></td>
							<td>{process.env.REACT_APP_CLIENT_SECRET}</td>
						</tr>
						{/* Show localStorage Data each in a row */}
						{Object.keys(localStorage).map((key, index) => {
							return (
								<tr key={index}>
									<td><b>LocalStorage: {key}:</b></td>
									<td>{localStorage.getItem(key)}</td>
								</tr>
							);
						})}
						
						{/* Show cookie Data each in a row */}
						{/* Get key and value from getCookieMap */}
						{Object.entries(getCookieMap()).map(([key, value], index) => {
							return (
								<tr key={index}>
									<td><b>Cookie: {key}:</b></td>
									<td>{value}</td>
								</tr>
							);
						})}
					</tbody>
				</table>

			</ContentBox>
		</div>
	);
}

export default SystemInformationView;