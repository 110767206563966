import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import LinkBox from '../../../components/LinkBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';
import formatPrice from '../../../utils/formatPrice';
import formatDate from '../../../utils/formatDate';

function DefaultProcessesBuchungenView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);
	const [transactions, setTransactions] = useState([]);

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/transactions`).then((res) => {
			setTransactions(res.data);
			console.log(res.data)
			setShowLoader(false);
		})
	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="computers-1.jpg"
					title="Buchungen verwalten"
					text="Verwalten Sie Ihre Buchungen und richten Sie den Buchungslauf für Ihr Unternehmen ein."

					hasCancelButton={false}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/")
					}}
				/>

				<ProcessSideContent>
					{transactions.length === 0 && (
						<div className="Empty-List">
							<h3>Es sind keine Buchungen vorhanden.</h3>
							<i>DP_BUCHUNGEN_VIEW_EMPTY</i>
						</div>
					)}
					{transactions.map((transaction, i) =>
						<LinkBox
							key={i}
							link={`/default-processes/internal-accounts/${transaction.debit_account.id}`}
							title={`Transaktions-Nr.: ` + transaction.id + `; ` + formatPrice(Math.abs(transaction.transaction_amount))}
							text={
								`
									${transaction.transaction_text_line_1}...<br />
									${transaction.debit_account.account_name} an ${transaction.credit_account.account_name}
									<br />
									<span style="margin-top:10px;display:inline-block">${formatDate(transaction.created_at)}${transaction.posting_run_id == 0 && ` <span style="color: #a31409;">(Buchungslauf noch offen!)</span>`}</span>
								`
							}
						/>
					)}
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesBuchungenView;