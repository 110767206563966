import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../../components/Loader';
import Content from '../../../../../components/Content';
import ContentBox from '../../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../../components/ProcessSideContent';
import FormInput from '../../../../../components/FormInput';
import FormSelect from '../../../../../components/FormSelect';
import FormTextarea from '../../../../../components/FormTextarea';
import FormCheckbox from '../../../../../components/FormCheckbox';

import { useStateWithCallbackLazy } from 'use-state-with-callback';

// Utils
import formatPrice from '../../../../../utils/formatPrice';

function DefaultProcessesVerwaltungBenutzerCreatePermissionView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false);

	const [permissionKeyInput, setPermissionKeyInput] = useState("");
	const [permissionValueCheckbox, setPermissionValueCheckbox] = useState(false);
	
	const [permissionKeyError, setPermissionKeyError] = useState(false);

	const resetAllErrors = () => {
		setPermissionKeyError(false);
	}

	const createPermission = () => {

		resetAllErrors();
		setShowLoader(true);

		/*
			Possible errors:
			permissionKeyError
		*/

		// Check if permissionKey is valid
		if (permissionKeyInput.length < 2) {
			setPermissionKeyError(true);
			setShowLoader(false);
			return;
		}

		// If all error-States are false, create customer
		if (!permissionKeyError) {
			// Create customer
			axios.post(`${process.env.REACT_APP_API_URL}/api/permissions`, {
				permission_key: permissionKeyInput,
				permission_value: permissionValueCheckbox,
				system_wide: true
			}).then((res) => {
				setShowLoader(false);
				if (res.data.success) {
					setShowLoader(false);
					navigate.push("/status/success")
				} else {
					setShowLoader(false);
					navigate.push("/status/error")
				}
			}).catch((err) => {
				setShowLoader(false);
				navigate.push("/status/error")
			})

		} else {
			setShowLoader(false);
			return;
		}

	}

	const slugify = text =>
		text
			.toString()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toUpperCase()
			.trim()
			.replace(/\s+/g, '-')
			.replace(/[^\w-]+/g, '')
			.replace(/--+/g, '-')

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Verwalten Sie SalesX-Berechtigungen &amp; Zugriffsrechte."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/verwaltung/benutzer");
					}}

					hasSubmitButton={true}
					submitButtonTitle="Berechtigung anlegen"
					submitButtonAction={() => {
						createPermission()
					}}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Berechtigung anlegen"
					>
						<FormInput
							label="Berechtigungs-Schlüssel"
							value={permissionKeyInput}
							onChange={(e) => {
								setPermissionKeyInput(slugify(e.target.value));
							}}
							required
							error={permissionKeyError}
						/>

						<FormCheckbox
							label="Standard-Berechtigung"
							checked={permissionValueCheckbox}
							onChange={(e) => {
								setPermissionValueCheckbox(e);
							}}
							text={permissionValueCheckbox ? "Aktiviert" : "Deaktiviert"}
						/>

						<br />

						<p>
							<b>Hinweis:</b> Dieser Berechtigungs-Schlüssel wird jedem bestehenden und zukünftigen Benutzer zugewiesen!
						</p>

					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungBenutzerCreatePermissionView;