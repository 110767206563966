import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Content from '../../../../components/Content';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import ContentBox from '../../../../components/ContentBox';
import FormInput from '../../../../components/FormInput';
import FormCheckbox from '../../../../components/FormCheckbox';
import FormSelect from '../../../../components/FormSelect';

function DefaultProcessesDeveloperFormsView() {

	let navigate = useHistory();
	const [inputForm, setInputForm] = useState("")
	const [checkboxForm, setCheckboxForm] = useState(true)
	const [selectForm, setSelectForm] = useState("1")

	return (
		<>
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Formulare"
					text="Testen Sie Formulare, Eingabefelder und Buttons."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/developer");
					}}
					
					hasSubmitButton={false}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Formulare"
					>

						<FormInput
							label="Eingabefeld (pflicht)"
							value={inputForm}
							onChange={(e) => {
								setInputForm(e.target.value);
							}}
							required
						/>

						<FormInput
							label="Eingabefeld (optional)"
							value={inputForm}
							onChange={(e) => {
								setInputForm(e.target.value);
							}}
						/>

						<FormInput
							label="Eingabefeld (platzhalter; Datums-Feld)"
							value={inputForm}
							onChange={(e) => {
								setInputForm(e.target.value);
							}}
							placeholder="TT.MM.JJJJ"
							formatdate
						/>

						<FormInput
							label="Eingabefeld (inaktiv)"
							value={inputForm}
							onChange={(e) => {
								setInputForm(e.target.value);
							}}
							disabled
						/>

						<FormSelect
							label="Auswahlfeld"
							value={selectForm}
							onChange={(e) => {
								setSelectForm(e.target.value);
							}}
							options={[
								{ value: "1", label: "Option 1" },
								{ value: "2", label: "Option 2" },
								{ value: "3", label: "Option 3" },
								{ value: "4", label: "Option 4" },
								{ value: "5", label: "Option 5" },
							]}
						/>

						<FormCheckbox
							label="Verbuchen am"
							checked={checkboxForm}
							onChange={(e) => {
								setCheckboxForm(e);
							}}
							text="Beim nächsten Buchungslauf"
						/>

					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesDeveloperFormsView;