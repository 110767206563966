import React, { useState, useId } from 'react';

// Styles
import './styles.scss';

function FormTextarea(props) {

	const htmlForId = useId();

	const maskDate = (event) => {
		let v = (event.target.value).replace(/\D/g,'').slice(0, 10);
		let returner = v;
		if (v.length >= 5) {
			returner = `${v.slice(0,2)}.${v.slice(2,4)}.${v.slice(4)}`;
		}
		else if (v.length >= 3) {
			returner = `${v.slice(0,2)}.${v.slice(2)}`;
		}
		event.target.value = returner.slice(0,10);
		return event;
	}
	
	const maskPrice = (event) => {
		let value = event.target.value;
		value = value.replace('€', '');
		value = value.replace(' ', '');
		
		if (value !== "" && !(value).includes('NaN')) {
			value = value.replace(' EUR', '');
			value = value.replace('&nbsp;', ' ');

			// Create our number formatter.
			var formatter = new Intl.NumberFormat('de-DE', {
				style: 'currency',
				currency: 'EUR',
			});

			event.target.value = formatter.format((value).replace(',', '.'));

			if (formatter.format((value).replace(',', '.')).includes("NaN")) {
				event.target.value = "0,00 €";
			}

			return event;
		}

		return event;

	}
	
	return (
		<div className={`FormTextarea${props.minimal ? ' FormTextarea--minimal' : ''}`}>
			{props.label && (
				<label htmlFor={htmlForId} className="FormTextarea-Label">{props.label}:</label>
			)}
			<textarea
				value={props.value}
				onChange={(event) => {
					if (props.formatdate) {
						props.onChange(maskDate(event))
					} else {
						props.onChange(event)
					}
				}}

				onBlur={(event) => {
					if (props.formatprice) {
						props.onChange(maskPrice(event))
					}

					if (props.inputblur) {
						props.inputblur(event)
					}
				}}

				required={props.required}
				placeholder={props.placeholder ? props.placeholder : (props.required ? "Bitte eingeben" : "Optional")}
			
				disabled={props.disabled}
				
				type="text"
				className={`FormTextarea-Input${(!props.label) ? ' FormTextarea-Input--noLabel' : ''}`}
				id={htmlForId}
			></textarea>
		</div>
	);
}

export default FormTextarea;