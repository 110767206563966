import React, {useState} from 'react';
import {
	useHistory
} from "react-router-dom"; 
import Modal from '../Modal';

// Assets
import LogoImage from '../../assets/images/logo-white.svg';

// Styles
import './styles.scss';

function Header(props) {

	const [expandMiniNavigator, setExpandMiniNavigator] = useState(false);
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const navigate = useHistory();

	const doLogout = () => {
		localStorage.clear();
		window.location.href = "/";
	}

	return (
		<>
			<div className="Header">
				<img src={LogoImage} className="Header-Logo" alt="SalesX" />
			</div>

			{props.isAuthenticated && (

				<div className={`Mini-Navigator ${(expandMiniNavigator ? "Mini-Navigator--expanded" : "")}`}>

					<a href="#null" onClick={(e) => {
						e.preventDefault();
						setExpandMiniNavigator(!expandMiniNavigator)
					}} className="Mini-Navigator-Item"><span class="material-symbols-rounded">{expandMiniNavigator ? "close" : "menu"}</span></a>

					{expandMiniNavigator && (
						<>
							<a href="#null" onClick={(e) => {
								e.preventDefault();
								setExpandMiniNavigator(false);
								navigate.push("/?t=" + Date.now());
							}} className="Mini-Navigator-Item"><span class="material-symbols-rounded">apps</span><small>Start</small></a>
		
							<a href="#null" onClick={(e) => {
								e.preventDefault();
								setExpandMiniNavigator(false);
								setShowLogoutModal(true);
							}} className="Mini-Navigator-Item"><span class="material-symbols-rounded">logout</span><small>Abmelden</small></a>
		
							<a href="#null" onClick={(e) => {
								e.preventDefault();
								setExpandMiniNavigator(false);
								navigate.push("/system-information?t=" + Date.now());
							}} className="Mini-Navigator-Item"><span class="material-symbols-rounded">info</span><small>System</small></a>						
						</>
					)}


				</div>

			)}

			{showLogoutModal && (
				<Modal
					title="Wirklich abmelden?"
					isClosable={true}
					closeAction={() => {
						setShowLogoutModal(false);
					}}

					actionButtons={[
						{
							title: "Ja, abmelden",
							type: "danger",
							action: () => {
								doLogout()
							}
						}
					]}
				>
					<p>Möchten Sie sich wirklich abmelden? Alle offenen Vorgänge werden dadurch storniert.</p>
				</Modal>
			)}

		</>
	);
}

export default Header;