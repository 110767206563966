import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

function Button(props) {
	return (
		<button
			className="Button"
			onClick={props.onClick}
		>{props.children}</button>
	);
}

export default Button;