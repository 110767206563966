import React from 'react';

// Styles
import './styles.scss';

function ContentBox(props) {
	return (
		<div className={`ContentBox ${props.warning ? 'ContentBox--warning' : ''}`} {...props}>
			{props.title && (
				<span className="ContentBox-Title">{props.title}</span>
			)}
			{props.children}
		</div>
	);
}

export default ContentBox;