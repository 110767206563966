import React from 'react';

// Styles
import './styles.scss';

function ProcessInformationBanner(props) {
	return (
		<div className="ProcessInformationBanner">
			<img src={require(`../../assets/images/ProcessInformationBanner/${props.image}`)} alt="" className="ProcessInformationBanner-Image" />
			<div className="ProcessInformationBanner-Content">
				<h3 className="ProcessInformationBanner-Content-Title">{props.title}</h3>
				<p className="ProcessInformationBanner-Content-Text">{props.text}</p>

				{props.actionButtons && (
					props.actionButtons.map((button, index) => (
						(!button.hidden) && (
							<button key={index} className="ProcessInformationBanner-Content-ActionButton" onClick={button.action}>
								<span>{button.title}</span>

								<span className="material-symbols-rounded">
									chevron_right
								</span>
							</button>
						)
					))
				)}

				{props.hasCancelButton && (
					<button className="ProcessInformationBanner-Content-CancelButton" onClick={props.cancelButtonAction}>
						<span>Abbrechen</span>

						<span className="material-symbols-rounded">
							chevron_right
						</span>
					</button>
				)}

				{props.hasSubmitButton && (
					<button className="ProcessInformationBanner-Content-SubmitButton" onClick={props.submitButtonAction}>
						<span>{props.submitButtonTitle ? props.submitButtonTitle : 'Bestätigen'}</span>
					</button>
				)}
			</div>
		</div>
	);
}

export default ProcessInformationBanner;