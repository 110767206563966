import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../components/Loader';
import Content from '../../../../components/Content';
import ContentBox from '../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import FormInput from '../../../../components/FormInput';
import FormCheckbox from '../../../../components/FormCheckbox';
import FormSelect from '../../../../components/FormSelect';
import Modal from '../../../../components/Modal';

// Utils
import formatPrice from '../../../../utils/formatPrice';

function DefaultProcessesProdukteEditView() {

	let { productId } = useParams();
	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);
	const [productDetails, setProductDetails] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [skuInput, setSkuInput] = useState("");
	const [nameInput, setNameInput] = useState("");
	const [descriptionInput, setDescriptionInput] = useState("./.");
	const [typeInput, setTypeInput] = useState("default");
	const [netPriceInput, setNetPriceInput] = useState("");
	const [taxRateInput, setTaxRateInput] = useState(19);
	const [taxValueInput, setTaxValueInput] = useState("");
	const [grossPriceInput, setGrossPriceInput] = useState("");

	const [skuInputError, setSkuInputError] = useState(false);
	const [nameInputError, setNameInputError] = useState(false);
	const [descriptionInputError, setDescriptionInputError] = useState(false);
	const [typeInputError, setTypeInputError] = useState(false);
	const [netPriceInputError, setNetPriceInputError] = useState(false);
	const [taxRateInputError, setTaxRateInputError] = useState(false);
	const [taxValueInputError, setTaxValueInputError] = useState(false);
	const [grossPriceInputError, setGrossPriceInputError] = useState(false);

	useEffect(() => {
		setShowLoader(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/products/` + productId).then((res) => {
			setProductDetails(res.data[0]);

			setSkuInput(res.data[0].product_sku);
			setNameInput(res.data[0].product_name);
			setDescriptionInput(res.data[0].product_description);
			setTypeInput(res.data[0].product_type);
			setNetPriceInput(formatPrice(res.data[0].product_net_price));
			setTaxRateInput(res.data[0].product_tax_rate);
			setTaxValueInput(formatPrice(res.data[0].product_tax));
			setGrossPriceInput(formatPrice(res.data[0].product_gross_price));

			setShowLoader(false);
		})
		
	}, [productId])

	const reverseFormatNumber = (val, locale) => {
        let group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
        let decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
        let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
        reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
        return Number.isNaN(reversedVal)?0:reversedVal;
    }

	const recalculatePrices = (inputType) => {

		let rawNetPrice = netPriceInput;
		let rawTaxRate = taxRateInput;
		let rawTaxValue = taxValueInput;
		let rawGrossPrice = grossPriceInput;

		// Unformat the prices
		rawNetPrice = rawNetPrice.replace(/\./g, "");
		rawTaxValue = rawTaxValue.replace(/\./g, "");
		rawGrossPrice = rawGrossPrice.replace(/\./g, "");

		// Remove dots and euro sign
		rawNetPrice = reverseFormatNumber(rawNetPrice, "de");
		rawTaxValue = reverseFormatNumber(rawTaxValue, "de");
		rawGrossPrice = reverseFormatNumber(rawGrossPrice, "de");

		// Convert the prices to numbers
		rawNetPrice = parseFloat(rawNetPrice);
		rawTaxValue = parseFloat(rawTaxValue);
		rawGrossPrice = parseFloat(rawGrossPrice);
		
		// Recalculate the other prices, based on the input type and the current input values.

		if (inputType === "netPrice") {
			// User has changed the net price. Calculate the gross price and the tax value, based on the tax rate.
			const grossPrice = formatPrice(rawNetPrice * (1 + (rawTaxRate / 100)));
			const taxValue = formatPrice(rawNetPrice * (rawTaxRate / 100));
			setGrossPriceInput(grossPrice);
			setTaxValueInput(taxValue);

			// Calculated. Now format this value and set it to the input.
			setNetPriceInput(formatPrice(rawNetPrice));
		}

		if (inputType === "grossPrice") {
			// User has changed the gross price. Calculate the net price and the tax value, based on the tax rate.
			const netPrice = formatPrice(rawGrossPrice / (1 + (rawTaxRate / 100)));
			const taxValue = formatPrice(rawGrossPrice - (rawGrossPrice / (1 + (rawTaxRate / 100))));
			setNetPriceInput(netPrice);
			setTaxValueInput(taxValue);

			// Calculated. Now format this value and set it to the input.
			setGrossPriceInput(formatPrice(rawGrossPrice));
		}

		if (inputType === "taxRate") {
			// User has changed the net price. Calculate the gross price and the tax value, based on the tax rate.
			const grossPrice = formatPrice(rawNetPrice * (1 + (rawTaxRate / 100)));
			const taxValue = formatPrice(rawNetPrice * (rawTaxRate / 100));
			setGrossPriceInput(grossPrice);
			setTaxValueInput(taxValue);

			// Calculated. Now format this value and set it to the input.
			setNetPriceInput(formatPrice(rawNetPrice));
		}

	}

	useEffect(() => {
		recalculatePrices('taxRate')
	}, [taxRateInput])

	const saveProduct = () => {
		setShowLoader(true);

		// Check if all fields are filled in.

		let allFieldsValid = true;
		if(skuInput.trim() === "") {
			allFieldsValid = false;
			setSkuInputError(true);
		}
		if(nameInput.trim() === "") {
			allFieldsValid = false;
			setNameInputError(true);
		}
		if(netPriceInput.trim() === "") {
			allFieldsValid = false;
			setNetPriceInputError(true);
		}
		if(grossPriceInput.trim() === "") {
			allFieldsValid = false;
			setGrossPriceInputError(true);
		}

		if(!allFieldsValid) {
			console.log('allFieldsValid', allFieldsValid);
			setShowLoader(false);
			return;
		}

		setSkuInputError(false);
		setNameInputError(false);
		setDescriptionInputError(false);
		setTypeInputError(false);
		setNetPriceInputError(false);
		setTaxRateInputError(false);
		setTaxValueInputError(false);
		setGrossPriceInputError(false);

		// ---

		let rawNetPrice = netPriceInput;
		let rawTaxRate = taxRateInput;
		let rawTaxValue = taxValueInput;
		let rawGrossPrice = grossPriceInput;

		// Unformat the prices
		rawNetPrice = rawNetPrice.replace(/\./g, "");
		rawTaxValue = rawTaxValue.replace(/\./g, "");
		rawGrossPrice = rawGrossPrice.replace(/\./g, "");

		// Remove dots and euro sign
		rawNetPrice = reverseFormatNumber(rawNetPrice, "de");
		rawTaxValue = reverseFormatNumber(rawTaxValue, "de");
		rawGrossPrice = reverseFormatNumber(rawGrossPrice, "de");

		// Convert the prices to numbers
		rawNetPrice = parseFloat(rawNetPrice);
		rawTaxValue = parseFloat(rawTaxValue);
		rawGrossPrice = parseFloat(rawGrossPrice);

		axios.put(`${process.env.REACT_APP_API_URL}/api/products/${productId}`, {
			sku: skuInput,
			name: nameInput,
			description: descriptionInput,
			type: typeInput,
			netPrice: rawNetPrice,
			taxRate: rawTaxRate,
			taxValue: rawTaxValue,
			grossPrice: rawGrossPrice,
		})
		.then(res => {
			if (res.data.status === "success") {
				setShowLoader(false);
				navigate.push("/status/success");
			} else {
				setShowLoader(false);
				navigate.push("/status/error");
			}
		})
		.catch(err => {
			setShowLoader(false);
			navigate.push("/status/error");
		})

	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="produkte-1.jpg"
					title="Produkte"
					text="Legen Sie Produkte an und drucken/exportieren Sie diese."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/produkte");
					}}

					hasSubmitButton={true}
					submitButtonTitle="Produkt speichern"
					submitButtonAction={() => {
						saveProduct()
					}}

					actionButtons={[
						{
							title: "Produkt löschen",
							action: () => {
								setShowDeleteModal(true)
							}
						}
					]}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Produkt Informationen"
					>
						<FormInput
							label="Artikel Nr."
							value={skuInput}
							onChange={(e) => {
								setSkuInput(e.target.value);
							}}
							required
							error={skuInputError}
						/>
						<FormInput
							label="Bezeichnung"
							value={nameInput}
							onChange={(e) => {
								setNameInput(e.target.value);
							}}
							required
							error={nameInputError}
						/>
						<FormInput
							label="Beschreibung"
							value={descriptionInput}
							onChange={(e) => {
								setDescriptionInput(e.target.value);
							}}
							error={descriptionInputError}
						/>
						<FormSelect
							label="Artikelgruppe"
							value={typeInput}
							onChange={(e) => {
								setTypeInput(e.target.value);
							}}
							options={[
								{ value: "default", label: "Physisch" },
								{ value: "digital", label: "Digital" },
								{ value: "service", label: "Dienstleistung" },
								{ value: "other_invoice_position", label: "Sonst. Rechnungs Pos." },
							]}
							error={typeInputError}
						/>
					</ContentBox>
					
					<ContentBox
						title="Preise"
					>
						<FormInput
							label="Netto Preis"
							value={netPriceInput}
							onChange={(e) => {
								setNetPriceInput(e.target.value)
							}}
							required
							formatprice
							onBlur={() => {
								recalculatePrices('netPrice')
							}}
							error={netPriceInputError}
						/>
						<FormSelect
							label="Steuersatz"
							value={taxRateInput}
							onChange={(e) => {
								setTaxRateInput(e.target.value)
							}}
							options={[
								{ value: 19, label: "19% Standard" },
								{ value: 7, label: "7% Ermäßigt" },
								{ value: 0, label: "0% MwSt. frei" }
							]}
							required
							error={taxRateInputError}
						/>
						<FormInput
							label="Steuer Wert"
							value={taxValueInput}
							onChange={(e) => {
								setTaxValueInput(e.target.value)
							}}
							required
							formatprice
							onBlur={() => {
								recalculatePrices('taxValue')
							}}
							disabled
							error={taxValueInputError}
						/>
						<FormInput
							label="Brutto Preis"
							value={grossPriceInput}
							onChange={(e) => {
								setGrossPriceInput(e.target.value)
							}}
							required
							formatprice
							onBlur={() => {
								recalculatePrices('grossPrice')
							}}
							error={grossPriceInputError}
						/>
					</ContentBox>
				</ProcessSideContent>
			</Content>

			{showDeleteModal && (
				<Modal
					title="Produkt löschen"
					isClosable={true}
					closeAction={() => {
						setShowDeleteModal(false);
					}}

					actionButtons={[
						{
							title: "Löschen",
							type: "danger",
							action: () => {
								setShowLoader(true);

								axios.delete(`${process.env.REACT_APP_API_URL}/api/products/` + productId).then((res) => {
									setShowLoader(false);
									if (res.data.status === "success") {
										setShowLoader(false);
										navigate.push("/status/success");
									} else {
										setShowLoader(false);
										navigate.push("/status/error");
									}
								})
							}
						}
					]}
				>
					<p>Möchtest du das Produkt wirklich löschen? Dadurch ist es in diesem Mandanten nicht mehr bestellbar oder einkaufbar.</p>
				</Modal>
			)}
		</>
	);
}

export default DefaultProcessesProdukteEditView;