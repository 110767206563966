import React from 'react';
import { Link } from "react-router-dom";

// Styles
import './styles.scss';

function LinkBox(props) {
	return (
		<Link to={props.link} className={`Link-Box-Item${props.disabled ? ' Link-Box-Item--disabled' : ''}`}>
			<div>
				<span className="Link-Box-Item-Title">{props.title}</span>
				<span className="Link-Box-Item-Text" dangerouslySetInnerHTML={{__html:props.text}}></span>
			</div>
			<span className="material-symbols-rounded">
				chevron_right
			</span>
		</Link>
	);
}

export default LinkBox;