import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import ContentBox from '../../../components/ContentBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';
import FormInput from '../../../components/FormInput';
import FormCheckbox from '../../../components/FormCheckbox';
import FormSelect from '../../../components/FormSelect';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import LinkBox from '../../../components/LinkBox';

// Utils
import formatPrice from '../../../utils/formatPrice';

function DefaultProcessesManuelleRechnungView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editBillId, setEditBillId] = useState(0);
	const [showRestoreModal, setShowRestoreModal] = useState(false);
	const [showCustomerSearchModal, setShowCustomerSearchModal] = useState(false);
	const [customerSearchResults, setCustomerSearchResults] = useState([]);
	const [showProductSearchModal, setShowProductSearchModal] = useState(false);
	const [productSearchIndex, setProductSearchIndex] = useState(0);
	const [productSearchResults, setProductSearchResults] = useState([]);
	const [customerNoInput, setCustomerNoInput] = useState("");
	const [nameInput, setNameInput] = useState("");
	const [addressInput, setAddressInput] = useState("");
	const [address2Input, setAddress2Input] = useState("");
	const [postcodeInput, setPostcodeInput] = useState("");
	const [cityInput, setCityInput] = useState("");
	const [invoiceNoInput, setInvoiceNoInput] = useState("RE" + Math.floor(100000000 + Math.random() * 900000000));
	const [paidCheckbox, setPaidCheckbox] = useState(false);
	const [valutaInput, setValutaInput] = useState(new Date().toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }));
	const [bookingTypeSelect, setBookingTypeSelect] = useState("outgoing_invoice");
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [paymentMethodSelect, setPaymentMethodSelect] = useState("");
	const [internalAccounts, setInternalAccounts] = useState([]);
	const [internalAccountSelect, setInternalAccountSelect] = useState("");
	const [periodicFirstInvoiceDateInput, setPeriodicFirstInvoiceDateInput] = useState("");
	const [smallBusinessMode, setSmallBusinessMode] = useState(false);
	const [directPaymentMethods, setDirectPaymentMethods] = useState([]);
	const [giroCodePaymentMethods, setGiroCodePaymentMethods] = useState([]);
	const [paymentGiroCodeCheckbox, setPaymentGiroCodeCheckbox] = useState(false);
	const [paymentDeadlineInput, setPaymentDeadlineInput] = useState(new Date(new Date().setDate(new Date().getDate() + 14)).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }));
	const [invoicePositions, setInvoicePositions] = useState([{
		sku: "",
		name: "",
		tax: "19",
		count: "1",
		unit_price: "0,00 €",
		total: "0,00 €"
	}]);
	const [internalTransferSollAccount, setInternalTransferSollAccount] = useState("");
	const [internalTransferHabenAccount, setInternalTransferHabenAccount] = useState("");
	const [internalTransferAmount, setInternalTransferAmount] = useState("0,00 €");

	const [showErrorInvoiceNoAlreadyExists, setShowErrorInvoiceNoAlreadyExists] = useState(false);
	const [availableInvoiceNo, setAvailableInvoiceNo] = useState("???");
	const [showWarningCustomerNotCreated, setShowWarningCustomerNotCreated] = useState(false);
	const [archiveFileId, setArchiveFileId] = useState("");
	const [defaultNewInvoicePositionTaxValue, setDefaultNewInvoicePositionTaxValue] = useState("19");

	useEffect(() => {
		loadPaymentMethods();
		loadInternalAccounts();
		checkStoredBillingData();
	}, [])

	const checkStoredBillingData = () => {
		const storedBillingData = JSON.parse(localStorage.getItem("billData"));
		if (storedBillingData) {
			console.log('storedBillingData', storedBillingData);

			// Get invoice number from stored data
			const storedInvoiceNo = storedBillingData.invoiceNoInput;

			// Check if a storage item with "editingBill" exists
			if (localStorage.getItem("editingBill")) {
				if (storedInvoiceNo === localStorage.getItem("editingBill")) {
					// If the stored invoice number matches the editing invoice number, ask the user if he wants to restore the data
					restoreFromBackup();
					setEditMode(true);
					setEditBillId(storedBillingData.editBillId);
					return;
				}
			}

			setShowRestoreModal(true);
		} else {
			createInvoiceNo();
		}
	}

	const restoreFromBackup = () => {
		setShowLoader(true);

		const storedBillingData = JSON.parse(localStorage.getItem("billData"));

		setInvoiceNoInput(storedBillingData.invoiceNoInput);
		setCustomerNoInput(storedBillingData.customerNoInput);
		setNameInput(storedBillingData.nameInput);
		setAddressInput(storedBillingData.addressInput);
		setAddress2Input(storedBillingData.address2Input);
		setPostcodeInput(storedBillingData.postcodeInput);
		setCityInput(storedBillingData.cityInput);
		setValutaInput(storedBillingData.valutaInput);
		setBookingTypeSelect(storedBillingData.bookingTypeSelect);
		setPeriodicFirstInvoiceDateInput(storedBillingData.periodicFirstInvoiceDateInput);
		setInvoicePositions(storedBillingData.invoicePositions);
		setPaidCheckbox(storedBillingData.paidCheckbox);
		setInternalAccountSelect(storedBillingData.internalAccountSelect);
		setPaymentGiroCodeCheckbox(storedBillingData.paymentGiroCodeCheckbox);

		setTimeout(() => {
			setShowRestoreModal(false);
			setShowLoader(false);

			localStorage.removeItem("billData");
		}, 600)

	}

	const createBackup = () => {
		// Store all input values into local storage to prevent data loss
		let localStorageData = {
			customerNoInput: customerNoInput,
			nameInput: nameInput,
			addressInput: addressInput,
			address2Input: address2Input,
			postcodeInput: postcodeInput,
			cityInput: cityInput,
			invoiceNoInput: invoiceNoInput,
			valutaInput: valutaInput,
			bookingTypeSelect: bookingTypeSelect,
			periodicFirstInvoiceDateInput: periodicFirstInvoiceDateInput,
			invoicePositions: invoicePositions,
			paidCheckbox: paidCheckbox,
			internalAccountSelect: internalAccountSelect,
			paymentGiroCodeCheckbox: paymentGiroCodeCheckbox
		}
		localStorage.setItem("billData", JSON.stringify(localStorageData));
	}

	const createInvoiceNo = () => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/bills/available-no`)
			.then((res) => {
				setShowLoader(false);
				console.log(res.data);
				setInvoiceNoInput(res.data);
			})
			.catch((err) => {
				setShowLoader(false);
				console.log(err);
			})
	}

	const searchCityByZip = (value) => {
		if (value.length === 5) {
			setShowLoader(true);
			axios.get(`${process.env.REACT_APP_API_URL}/api/search-city/${value}`)
				.then((res) => {
					setShowLoader(false);
					if (res.data == null) {
						setCityInput("");
					} else {
						setCityInput(res.data);
					}
				})
				.catch((err) => {
					setShowLoader(false);
					console.log(err);
				})
		}
	}

	const searchArticleNumber = (value, index) => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/products/getByArticleNo/${value}`)
			.then((res) => {
				setShowLoader(false);
				if (res.data[0]) {
					setInvoicePositions((prevState) => {
						return [...prevState.slice(0, index), {
							...prevState[index],
							sku: value,
							name: res.data[0].product_name,
							tax: res.data[0].product_tax_rate.toString(),
							unit_price: formatPrice(res.data[0].product_gross_price),
							total: formatPrice(res.data[0].product_gross_price)
						}, ...prevState.slice(index + 1)]
					})
				}
			})
			.catch((err) => {
				setShowLoader(false);
			})
	}

	const searchCustomerNumber = (value) => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/customers/getByCustomerNo/${value}`)
			.then((res) => {
				setShowLoader(false);
				if (res.data) {
					
					// If Company Name is given, set this
					if (res.data.company_name.length > 1) {
						setNameInput(res.data.company_name);
					} else {
						setNameInput(res.data.firstname + " " + res.data.lastname);
					}

					setAddressInput(res.data.address_line_1);
					setAddress2Input(res.data.address_line_2);
					setPostcodeInput(res.data.zip);
					setCityInput(res.data.city);
				}
			})
			.catch((err) => {
				setShowLoader(false);
			})
	}

	const searchCustomerByName = (value) => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/customers/getByCustomerName?name=${value}`)
			.then((res) => {
				setShowLoader(false);
				if (res.data) {
					if (res.data.length > 0) {

						setCustomerSearchResults(res.data);
						setShowCustomerSearchModal(true);

					}
				}
			})
			.catch((err) => {
				setShowLoader(false);
			})
	}

	const searchProductByName = (value, index) => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/products/getByProductName?name=${value}`)
			.then((res) => {
				setShowLoader(false);
				if (res.data) {
					if (res.data.length > 0) {

						setProductSearchResults(res.data);
						setProductSearchIndex(index);
						setShowProductSearchModal(true);

					}
				}
			})
			.catch((err) => {
				setShowLoader(false);
			})
	}

	const loadPaymentMethods = () => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/payment-methods`)
			.then((res) => {
				setShowLoader(false);

				let paymentMethods = [];
				let directPaymentMethods = [];
				let giroCodePaymentMethods = [];
				res.data.forEach((paymentMethod) => {

					if (paymentMethodSelect === "") {
						setPaymentMethodSelect(paymentMethod.id);
					}

					if (paymentMethod.payment_method_direct_payment === 1) {
						directPaymentMethods.push(paymentMethod.id);
					}

					if (paymentMethod.payment_method_allow_girocode === 1) {
						giroCodePaymentMethods.push(paymentMethod.id);
					}

					paymentMethods.push({
						value: paymentMethod.id,
						label: paymentMethod.payment_method_name + " (" + paymentMethod.payment_method_description + ")",
						defaultDeadline: paymentMethod.payment_method_payment_days
					})
				})

				setPaymentMethods(paymentMethods);
				setDirectPaymentMethods(directPaymentMethods);
				setGiroCodePaymentMethods(giroCodePaymentMethods);
			})
			.catch((err) => {
				setShowLoader(false);
				console.log(err);
			})
	}

	const loadInternalAccounts = () => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/internal-accounts`)
			.then((res) => {
				setShowLoader(false);

				// Get only internal accounts where account_parent_id = 35
				let internalAccounts = [];
				res.data.forEach((internalAccount) => {
					if (internalAccount.account_parent_id === 35) {
						internalAccounts.push({
							value: internalAccount.id,
							label: internalAccount.account_name
						})
					}
				})

				setInternalAccounts(internalAccounts);
			})
			.catch((err) => {
				setShowLoader(false);
				console.log(err);
			})
	}

	const createBill = (forceInvoiceNo = null) => {

		// Check if all required fields are filled
		if (nameInput.length < 1 || addressInput.length < 1 || postcodeInput.length < 1 || cityInput.length < 1 || invoiceNoInput.length < 1) {
			alert("Bitte fülle alle Pflichtfelder aus!");
			return;
		}

		// Store all input values into local storage to prevent data loss
		let localStorageData = {
			customerNoInput: customerNoInput,
			nameInput: nameInput,
			addressInput: addressInput,
			address2Input: address2Input,
			postcodeInput: postcodeInput,
			cityInput: cityInput,
			invoiceNoInput: invoiceNoInput,
			valutaInput: valutaInput,
			bookingTypeSelect: bookingTypeSelect,
			periodicFirstInvoiceDateInput: periodicFirstInvoiceDateInput,
			invoicePositions: invoicePositions,
			paidCheckbox: paidCheckbox,
			internalAccountSelect: internalAccountSelect,
			paymentMethodSelect: paymentMethodSelect,
			paymentDeadlineInput: paymentDeadlineInput,
			paymentGiroCodeCheckbox: paymentGiroCodeCheckbox,
			internalTransferSollAccount: internalTransferSollAccount,
			internalTransferHabenAccount: internalTransferHabenAccount,
			internalTransferAmount: internalTransferAmount
		}
		localStorage.setItem("billData", JSON.stringify(localStorageData));

		let customInvoiceNo = (forceInvoiceNo !== null ? forceInvoiceNo : invoiceNoInput);

		let tmpInvoicePositions = invoicePositions;


		if (bookingTypeSelect == "internal_transfer") {
			// Create a pseudo invoice position for the internal transfer
			tmpInvoicePositions = [{
				sku: "",
				name: "SOLL: " + internalTransferSollAccount,
				tax: "0",
				count: "1",
				unit_price: "-" + internalTransferAmount,
				total: "-" + internalTransferAmount
			},{
				sku: "",
				name: "HABEN: " + internalTransferHabenAccount,
				tax: "0",
				count: "1",
				unit_price: internalTransferAmount,
				total: internalTransferAmount
			}]

		}


		setShowLoader(true);
		axios.post(`${process.env.REACT_APP_API_URL}/api/bills`, {
			customerNoInput: customerNoInput,
			nameInput: nameInput,
			addressInput: addressInput,
			address2Input: address2Input,
			postcodeInput: postcodeInput,
			cityInput: cityInput,
			invoiceNoInput: customInvoiceNo,
			valutaInput: valutaInput,
			bookingTypeSelect: bookingTypeSelect,
			periodicFirstInvoiceDateInput: periodicFirstInvoiceDateInput,
			invoicePositions: tmpInvoicePositions,
			paidCheckbox: (bookingTypeSelect === "offer" ? false : paidCheckbox),
			internalAccountSelect: internalAccountSelect,
			paymentMethodSelect: paymentMethodSelect,
			paymentDeadlineInput: paymentDeadlineInput,
			paymentGiroCodeCheckbox: paymentGiroCodeCheckbox
		})
			.then((res) => {
				setShowLoader(false);
				if (res.data.status === "success") {
					setShowLoader(false);

					
					if (res.data.data.hasCustomer === false) {
						setArchiveFileId(res.data.data.archive_file_id);
						setShowWarningCustomerNotCreated(true);
					} else {
						axios.get(`${process.env.REACT_APP_API_URL}/api/archive/5/${res.data.data.archive_file_id}`, {
							responseType: 'blob'
						})
						.then((res) => {
							setShowLoader(false);
	
							const url = window.URL.createObjectURL(new Blob([res.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', invoiceNoInput + ".pdf");
							document.body.appendChild(link);
							link.click();
						})

						navigate.push(`/status/success?is_bill=true&bill_id=${res.data.data.bill_id}&bill_identifier=${res.data.data.bill_identifier}&archive_file_id=${res.data.data.archive_file_id}`);
					}
				} else {
					if (res.data.error) {
						if (res.data.error === "invoice_no_already_in_use") {

							axios.get(`${process.env.REACT_APP_API_URL}/api/bills/available-no`)
								.then((res) => {
									setShowErrorInvoiceNoAlreadyExists(true);
									setAvailableInvoiceNo(res.data);
									setShowLoader(false);		
								})
								.catch((err) => {
									setShowLoader(false);
									navigate.push("/status/error")
								})

						} else {
							setShowLoader(false);
							navigate.push("/status/error")
						}
					} else {
						setShowLoader(false);
						navigate.push("/status/error")
					}
				}
			}).catch((err) => {
				setShowLoader(false);
				navigate.push("/status/error")
			})

	}

	const editBill = () => {
		axios.put(`${process.env.REACT_APP_API_URL}/api/bills/status/${editBillId}/cancelled`).then((res) => {
			console.log(res.data);

			if (res.data.status === "success") {
				createBill(invoiceNoInput);
				localStorage.removeItem("billData");
				localStorage.removeItem("editingBill");
			} else {
				setShowLoader(false);
				navigate.push("/status/error");
			}

		}).catch((err) => {
			setShowLoader(false);
			navigate.push("/status/error");
		})
	}

	const formatCurrency = (value) => {
		value = value.toString();
		value = value.replace('€', '');
		value = value.replace(' ', '');

		
		if (value !== "" && !(value).includes('NaN')) {
			value = value.replace(' EUR', '');
			value = value.replace('&nbsp;', ' ');

			// Create our number formatter.
			var formatter = new Intl.NumberFormat('de-DE', {
				style: 'currency',
				currency: 'EUR',
			});

			value = formatter.format((value).replace(',', '.'));

			return value;
		}

		return value;

	}

	const calculateTotal = () => {
		let total = 0;
		invoicePositions.forEach((position) => {
			if (position.total === "") {
				total += 0;
			} else {
				total += parseFloat(position.total.replaceAll('€', '').replaceAll(".","").replaceAll(' ', '').replaceAll(",","."));
			}
		});

		if (bookingTypeSelect === "internal_transfer") {
			total = parseFloat(internalTransferAmount.replaceAll('€', '').replaceAll(".","").replaceAll(' ', '').replaceAll(",","."));
		}

		return total;
	}

	const calculateNetTotal = () => {
		let netTotal = 0;
		invoicePositions.forEach((position) => {

			if (position.total === "") {
				netTotal += 0;
			} else {
				let multiplicator = 1 + (parseFloat(position.tax) / 100);
				netTotal += parseFloat(position.total.replace('€', '').replace(".","").replace(' ', '').replace(",",".")) / multiplicator;
			}

		});

		return netTotal;
	}

	const createCustomer = () => {

		// Store customer in local storage
		localStorage.setItem("customer", JSON.stringify({
			nameInput: nameInput,
			addressInput: addressInput,
			address2Input: address2Input,
			postcodeInput: postcodeInput,
			cityInput: cityInput,
			archiveFileId: archiveFileId
		}))

		setShowWarningCustomerNotCreated(false);
		navigate.push("/default-processes/kunden/create");

	}

	const switchToSmallBusinessMode = () => {
		// Set all invoicePosition taxes to 0%
		let newInvoicePositions = invoicePositions;
		newInvoicePositions.forEach((position) => {
			position.tax = "0";
		});

		setInvoicePositions(newInvoicePositions);

		setDefaultNewInvoicePositionTaxValue("0");
	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="manuelle-rechnung-1.jpg"
					title={editMode ? "Rechnung bearbeiten" : "Manuelle Rechnung"}
					text={editMode ? "Bearbeiten Sie Ihre bereits angelegte Rechnung. Die alte Rechnung wird automatisch storniert." : "Legen Sie manuelle Rechnungen an und drucken/exportieren Sie diese."}

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/");
					}}

					hasSubmitButton={true}
					submitButtonTitle={editMode ? "Rechnung speichern" : "Buchung abschließen"}
					submitButtonAction={() => {
						if (editMode) {editBill()} else {createBill()}
					}}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Rechnungsdaten"
					>
						<FormInput
							label="Kundennr."
							value={customerNoInput}
							onChange={(e) => {
								setCustomerNoInput(e.target.value.replace("#", ""));
							}}
							onBlur={(e) => {
								if (e.target.value.length > 0) {
									searchCustomerNumber(e.target.value.replace("#", ""));
								}
							}}
						/>
						<FormInput
							label="Name / Firma"
							value={nameInput}
							onChange={(e) => {
								setNameInput(e.target.value);
							}}
							onBlur={(e) => {
								if (e.target.value.length > 0) {
									searchCustomerByName(e.target.value);
								}
							}}
							required
						/>
						<FormInput
							label="Adresszeile 1"
							value={addressInput}
							onChange={(e) => {
								setAddressInput(e.target.value);
							}}
							required
						/>
						<FormInput
							label="Adresszeile 2"
							value={address2Input}
							onChange={(e) => {
								setAddress2Input(e.target.value);
							}}
						/>
						<FormInput
							label="Postleitzahl"
							value={postcodeInput}
							onChange={(e) => {
								setPostcodeInput(e.target.value);
							}}
							onBlur={(e) => {
								// Search for article number
								if (e.target.value.length > 0) {
									searchCityByZip(e.target.value);
								}
							}}
							required
						/>
						<FormInput
							label="Ort"
							value={cityInput}
							onChange={(e) => {
								setCityInput(e.target.value);
							}}
							required
						/>
					</ContentBox>
					
					<ContentBox
						title="Buchung"
					>
						<FormInput
							label="Nummer"
							value={invoiceNoInput}
							customOnChange={(e) => {
								setInvoiceNoInput(e.target.value);
							}}
							disabled={editMode}
						/>
						<FormInput
							label="Rechnungsdatum"
							value={valutaInput}
							customOnChange={(e) => {
								setValutaInput(e.target.value);
							}}
							formatdate
							placeholder="DD.MM.JJJJ"
						/>
						<FormSelect
							label="Buchungsart"
							value={bookingTypeSelect}
							onChange={(e) => {
								setBookingTypeSelect(e.target.value);

								if (e.target.value === "offer") {
									// Get first 2 letters of invoiceNoInput
									let invoiceNoInputFirstTwoLetters = invoiceNoInput.substring(0, 2);
									if (invoiceNoInputFirstTwoLetters === "RE") {
										setInvoiceNoInput("AN" + invoiceNoInput.substring(2));
									}
								} else if (e.target.value === "internal_transfer") {

									setShowLoader(true);

									setPaidCheckbox(true);

									let invoiceNoInputFirstTwoLetters = invoiceNoInput.substring(0, 2);
									setInvoiceNoInput("BU" + invoiceNoInput.substring(2));

									// Get config
									axios.get(`${process.env.REACT_APP_API_URL}/api/configuration`)
									.then((res) => {
										res.data.forEach(configItem => {
											if (configItem.configuration_name === "COMPANY_NAME") {
												setNameInput(configItem.configuration_value)
											}
											if (configItem.configuration_name === "COMPANY_OWNER") {
												setAddressInput(configItem.configuration_value)
											}
											if (configItem.configuration_name === "COMPANY_ADDRESS") {
												setAddress2Input(configItem.configuration_value)
											}
											if (configItem.configuration_name === "COMPANY_CITY") {
												setCityInput(configItem.configuration_value)
											}
											if (configItem.configuration_name === "COMPANY_ZIP") {
												setPostcodeInput(configItem.configuration_value)
											}
										})
										setShowLoader(false);
									})

								} else {
									// Get first 2 letters of invoiceNoInput
									let invoiceNoInputFirstTwoLetters = invoiceNoInput.substring(0, 2);
									if (invoiceNoInputFirstTwoLetters === "AN") {
										setInvoiceNoInput("RE" + invoiceNoInput.substring(2));
									}
								}

							}}
							options={[
								{ value: "incoming_invoice", label: "Eingehende Rechnung" },
								{ value: "outgoing_invoice", label: "Ausgehende Rechnung" },
								{ value: "internal_transfer", label: "Interne Umbuchung Geldtransfer" },
								{ value: "internal_transfer_accounting", label: "Interne Umbuchung Kontenrahmen" },
								{ value: "cancellation", label: "Stornobuchung" },
								{ value: "periodic", label: "Periodische Buchung (mtl.)" },
								{ value: "offer", label: "Angebot" }
							]}
						/>
						{bookingTypeSelect === "periodic" && (
							<FormInput
								label="Erste Rechnungsstellung"
								value={periodicFirstInvoiceDateInput}
								customOnChange={(e) => {
									setPeriodicFirstInvoiceDateInput(e.target.value);
								}}
								formatdate
								placeholder="DD.MM.JJJJ"
							/>
						)}
						{(bookingTypeSelect !== "offer" && bookingTypeSelect !== "cancellation" && bookingTypeSelect !== "internal_transfer") && (
							<FormCheckbox
								label="Saldo"
								checked={paidCheckbox}
								onChange={(e) => {
									setPaidCheckbox(e);
								}}
								text="Bereits bezahlt"
							/>
						)}
						{(bookingTypeSelect !== "offer" && bookingTypeSelect !== "cancellation" && bookingTypeSelect !== "internal_transfer") && (
							<>
								<FormSelect
									label="Zahlungsmethode"
									value={paymentMethodSelect}
									onChange={(e) => {
										setPaymentMethodSelect(e.target.value);

										paymentMethods.forEach((paymentMethod, index) => {
											// eslint-disable-next-line eqeqeq
											if (paymentMethod.value == e.target.value) {
												const newDate = new Date();
												newDate.setDate(newDate.getDate() + paymentMethods[index].defaultDeadline);
												// Set setPaymentDeadlineInput (dd.mm.yyyy)
												setPaymentDeadlineInput(newDate.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }));
											}
										});

									}}
									options={paymentMethods}
								/>
								{!directPaymentMethods.includes(parseFloat(paymentMethodSelect)) ? (
									<FormInput
										label="Zahlungsfrist"
										value={paymentDeadlineInput}
										customOnChange={(e) => {
											setPaymentDeadlineInput(e.target.value);
										}}
										formatdate
										placeholder="DD.MM.JJJJ"
									/>
								) : null}
								
								{giroCodePaymentMethods.includes(parseFloat(paymentMethodSelect)) ? (
									<FormCheckbox
										label="GiroCode"
										checked={paymentGiroCodeCheckbox}
										onChange={(e) => {
											setPaymentGiroCodeCheckbox(e);
										}}
										text="Zahlungs-QR-Code auf Rechnung anzeigen"
									/>
								) : null}

								{bookingTypeSelect === "outgoing_invoice" && (
									<FormCheckbox
										label="Besteuerung"
										checked={smallBusinessMode}
										onChange={(e) => {
											setSmallBusinessMode(e)
											if (e === true) {
												console.log("switch to small business mode");
												switchToSmallBusinessMode();
											}
										}}
										text="Als Kleinunternehmer gem. §19 UStG. behandeln"
									/>
								)}
							</>
						)}
					</ContentBox>
					
					{bookingTypeSelect !== "internal_transfer" ? (
						<ContentBox
							title="Rechnungspositionen"
						>
							<table style={{width:'100%',marginLeft: "-3px"}}>
								<thead>
									<tr>
										<th align='right'>Pos.</th>
										<th align='left'>Art.-Nr.</th>
										<th align='left'>Bezeichnung</th>
										<th align='left'>Menge</th>
										<th align='left'>MwSt.</th>
										<th align='left'>Einzelpreis</th>
										<th align='left'>Gesamtpreis</th>
									</tr>
								</thead>
								<tbody>
									{invoicePositions.map((invoicePosition, index) => (
										<tr key={index}>
											<td style={{position:'relative',width:'100px'}}>
												<FormInput
													value={(String(index+1).padStart(2,'0'))}
													required
													disabled
												/>
											</td>
											<td style={{position:'relative'}}>
												<FormInput
													value={invoicePosition.sku}
													onChange={(e) => {
														// Update Array with new value
														let newInvoicePositions = [...invoicePositions];
														newInvoicePositions[index].sku = e.target.value;
														setInvoicePositions(newInvoicePositions);
													}}
													onBlur={(e) => {
														// Search for article number
														if (e.target.value.length > 0) {
															if (invoicePositions[index].name.length === 0) {
																searchArticleNumber(e.target.value, index);
															}
														}
													}}
													required
												/>
											</td>
											<td style={{position:'relative'}}>
												<FormInput
													value={invoicePosition.name}
													onChange={(e) => {
														// Update Array with new value
														let newInvoicePositions = [...invoicePositions];
														newInvoicePositions[index].name = e.target.value;
														setInvoicePositions(newInvoicePositions);
													}}
													onBlur={(e) => {
														if (invoicePosition.sku.length === 0) {
															if (e.target.value.length > 0) {
																if (e.target.value !== e.target.oldValue) {
																	e.target.oldValue = e.target.value;
																	searchProductByName(e.target.value, index);
																}
															}
														}
													}}
													required
												/>
											</td>
											<td>
												<FormInput
													value={invoicePosition.count}
													onChange={(e) => {
														// Update Array with new value
														let newInvoicePositions = [...invoicePositions];
														newInvoicePositions[index].count = e.target.value;
														setInvoicePositions(newInvoicePositions);
													}}
													required
													type="number"
													min="1"
	
													inputblur={() => {
														// Calculate Total Cost
														// Unformat invoicePosition.unit_price and multiplicate with count
														let newInvoicePositions = [...invoicePositions];
														let newValue = parseFloat(invoicePosition.unit_price.replace(/\./g, '').replace(".","").replace(",",".").replace("€","").trim()) * invoicePosition.count;
														if (!isNaN(newValue)) {
															newInvoicePositions[index].total = formatPrice(newValue.toFixed(2));
															setInvoicePositions(newInvoicePositions);
														}
													}}
												/>
											</td>
											<td>
												<FormSelect
													value={invoicePosition.tax}
													onChange={(e) => {
														// Update Array with new value
														let newInvoicePositions = [...invoicePositions];
														newInvoicePositions[index].tax = e.target.value;
														setInvoicePositions(newInvoicePositions);
													}}
													options={[
														{ value: "19", label: "19% Standard" },
														{ value: "7", label: "7% Ermäßigt" },
														{ value: "0", label: "0% MwSt. frei" }
													]}
													required
												/>
											</td>
											<td>
												<FormInput
													value={invoicePosition.unit_price}
													onChange={(e) => {
														// Update Array with new value
														let newInvoicePositions = [...invoicePositions];
														newInvoicePositions[index].unit_price = e.target.value;
														setInvoicePositions(newInvoicePositions);
													}}
													required
													formatprice
	
													inputblur={() => {
														// Calculate Total Cost
														// Unformat invoicePosition.unit_price and multiplicate with count
														let newInvoicePositions = [...invoicePositions];
														let newValue = parseFloat(invoicePosition.unit_price.replace(/\./g, '').replace(".","").replace(",",".").replace("€","").trim()) * invoicePosition.count;
														if (!isNaN(newValue)) {
															newInvoicePositions[index].total = formatPrice(newValue.toFixed(2));
															setInvoicePositions(newInvoicePositions);
														}
													}}
												/>
											</td>
											<td>
												<FormInput
													value={invoicePosition.total}
													onChange={(e) => {
														// Update Array with new value
														let newInvoicePositions = [...invoicePositions];
														newInvoicePositions[index].total = e.target.value;
														setInvoicePositions(newInvoicePositions);
													}}
													required
													formatprice
	
													inputblur={() => {
														// Calculate Total Cost
														// Unformat invoicePosition.unit_price and multiplicate with count
														let newInvoicePositions = [...invoicePositions];
														let newValue = parseFloat(invoicePosition.unit_price.replace(/\./g, '').replace(".","").replace(",",".").replace("€","").trim()) * invoicePosition.count;
														if (!isNaN(newValue)) {
															newInvoicePositions[index].total = formatPrice(newValue.toFixed(2));
															setInvoicePositions(newInvoicePositions);
														}
													}}
												/>
											</td>
											<td>
												<button onClick={() => {
													// Remove invoicePosition from Array
													let newInvoicePositions = [...invoicePositions];
	
													if (newInvoicePositions.length === 1) {
														newInvoicePositions = [{
															count: "1",
															sku: "",
															name: "",
															tax: defaultNewInvoicePositionTaxValue,
															unit_price: "0,00 €",
															total: "0,00 €"
														}]
													} else {
														newInvoicePositions.splice(index, 1);
													}
	
													setInvoicePositions(newInvoicePositions);
												}} className="DeleteInvoicePositionButton">
													<span className="material-symbols-rounded">
														delete
													</span>
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							<br />
							<Button onClick={() => {
								// Add new invoicePosition to Array
								let newInvoicePositions = [...invoicePositions];
								newInvoicePositions.push({
									count: "1",
									name: "",
									tax: defaultNewInvoicePositionTaxValue,
									unit_price: "0,00 €",
									total: "0,00 €"
								});
								setInvoicePositions(newInvoicePositions);
							}}>Neue Position</Button>
						</ContentBox>
					) : (
						<ContentBox
							title="Buchungsdetails"
						>
							<table style={{width:'100%',marginLeft: "-3px"}}>
								<thead>
									<tr>
										<th align='left'>Konto SOLL</th>
										<th align='left'>Konto HABEN</th>
										<th align='left'>Betrag</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<FormInput
												value={internalTransferSollAccount}
												onChange={(e) => {
													setInternalTransferSollAccount(e.target.value);
												}}
												required
											/>
										</td>
										<td>
											<FormInput
												value={internalTransferHabenAccount}
												onChange={(e) => {
													setInternalTransferHabenAccount(e.target.value);
												}}
												required
											/>
										</td>
										<td>
											<FormInput
												value={internalTransferAmount}
												onChange={(e) => {
													if (e.target.value < 0) {
														e.target.value = e.target.value * -1;
													}
													setInternalTransferAmount(e.target.value);
												}}
												required
												formatprice
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</ContentBox>
					)}

					<ContentBox title="Rechnung:">
						<table className="table-view">
							<tbody>
								{bookingTypeSelect !== "internal_transfer" && (
									<>
										<tr>
											<td className="table-view-title">
												Netto:
											</td>
											<td className="table-view-content">
												{ formatCurrency(calculateNetTotal()) }
											</td>
										</tr>
										<tr>
											<td className="table-view-title">
												Davon Steuer:
											</td>
											<td className="table-view-content">
												{ formatCurrency(calculateTotal() - calculateNetTotal()) }
											</td>
										</tr>
									</>
								)}
								<tr>
									<td className="table-view-title table-view-title--highlighted">
										TOTAL Buchungsbetrag:
									</td>
									<td className="table-view-content table-view-content--highlighted">
										{ formatCurrency(calculateTotal()) }
									</td>
								</tr>
							</tbody>
						</table>
					</ContentBox>

				</ProcessSideContent>
			</Content>

			{showCustomerSearchModal && (
				<Modal
					title="Kunde auswählen"
					isClosable={true}
					closeAction={() => {
						setShowCustomerSearchModal(false);
					}}
				>
					<p>Unter dem angegebenen Namen wurden folgende Kunden gefunden:</p>

					{customerSearchResults.map((customer, index) => (
						<ContentBox
							onClick={() => {	
								// If Company Name is given, set this
								if (customer.company_name !== "./.") {
									setNameInput(customer.company_name);
								} else {
									setNameInput(customer.firstname + " " + customer.lastname);
								}

								setCustomerNoInput(customer.customer_number);
								setAddressInput(customer.address_line_1);
								setAddress2Input(customer.address_line_2);
								setPostcodeInput(customer.zip);
								setCityInput(customer.city);

								setShowCustomerSearchModal(false);
							}}
							style={{
								cursor: "pointer",
								marginBottom: "10px"
							}}
							key={index}
							title={"#" + customer.customer_number}
						>
							<p>
								{(customer.company_name !== null && customer.company_name !== "") && (<>{customer.company_name}<br /></>)}
								{customer.firstname} {customer.lastname}<br />
								{(customer.address_line_1 !== null && customer.address_line_1 !== "") && (<>{customer.address_line_1}<br /></>)}
								{(customer.address_line_2 !== null && customer.address_line_2 !== "") && (<>{customer.address_line_2}<br /></>)}
								{customer.zip} {customer.city}
							</p>
						</ContentBox>
					))}

				</Modal>
			)}

			{showProductSearchModal && (
				<Modal
					title="Artikel auswählen"
					isClosable={true}
					closeAction={() => {
						setShowProductSearchModal(false);
					}}
				>
					<p>Unter der angegebenen Bezeichnung wurden folgende Artikel gefunden:</p>

					{productSearchResults.map((product, index) => (
						<ContentBox
							onClick={() => {
								setInvoicePositions((prevState) => {
									return [...prevState.slice(0, productSearchIndex), {
										...prevState[productSearchIndex],
										sku: product.product_sku,
										name: product.product_name,
										tax: product.product_tax_rate.toString(),
										unit_price: formatPrice(product.product_gross_price),
										total: formatPrice(product.product_gross_price)
									}, ...prevState.slice(productSearchIndex + 1)]
								})

								setShowProductSearchModal(false);

							}}
							style={{
								cursor: "pointer",
								marginBottom: "10px"
							}}
							key={index}
							title={"#" + product.product_sku}
						>
							<p>
								{product.product_name}<br />
								{product.product_description}<br />

								<div style={{
									paddingTop: '10px',
									marginTop: '10px',
									borderTop: '1px solid #e2e2e2'
								}}>
									<b>Brutto:</b> {formatPrice(product.product_gross_price)}
									<br />
									<b>Netto:</b> {formatPrice(product.product_net_price)}
								</div>

							</p>
						</ContentBox>
					))}

				</Modal>
			)}

			{showRestoreModal && (
				<Modal
					title="Aus der Sicherungkopie wiederherstellen?"
					isClosable={true}
					closeAction={() => {
						localStorage.removeItem("billData");	
						setShowRestoreModal(false);
					}}

					actionButtons={[
						{
							title: "Ja, wiederherstellen",
							action: () => {
								restoreFromBackup();
							}
						}
					]}
				>
					<p>Möchten Sie die Rechnungsdaten aus der letzten Sicherungskopie wiederherstellen? Bei "nein" wird die Sicherungskopie gelöscht!</p>

				</Modal>
			)}

			{showErrorInvoiceNoAlreadyExists && (
				<Modal
					title="Ein Fehler ist aufgetreten!"
					isClosable={true}
					closeAction={() => {
						setShowErrorInvoiceNoAlreadyExists(false);
					}}

					actionButtons={[
						{
							title: availableInvoiceNo + " verwenden",
							action: () => {
								setShowLoader(true);
								setInvoiceNoInput(availableInvoiceNo);
								setShowErrorInvoiceNoAlreadyExists(false);
								setTimeout(() => {
									createBill(availableInvoiceNo);
								}, 500);
							}
						}
					]}
				>
					<p>Die Rechnung Nr. {invoiceNoInput} existiert bereits! Möchten Sie stattdessen {availableInvoiceNo} verwenden?</p>
				</Modal>
			)}

			{showWarningCustomerNotCreated && (
				<Modal
					title="Hinweis!"
					isClosable={true}
					closeAction={() => {
						setShowWarningCustomerNotCreated(false);
						setShowLoader(true);

						axios.get(`${process.env.REACT_APP_API_URL}/api/archive/5/${archiveFileId}`, {
							responseType: 'blob'
						})
						.then((res) => {
							setShowLoader(false);
	
							const url = window.URL.createObjectURL(new Blob([res.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', invoiceNoInput + ".pdf");
							document.body.appendChild(link);
							link.click();
						})

						navigate.push(`/status/success?is_bill=true&archive_file_id=${archiveFileId}`);

					}}

					actionButtons={[
						{
							title: "Kunde erstellen",
							action: () => {
								createCustomer()
							}
						}
					]}
				>
					<p>Dieser Kunde ist noch nicht im System erfasst. Möchten Sie ihn jetzt erstellen?</p>
				</Modal>
			)}
		</>
	);
}

export default DefaultProcessesManuelleRechnungView;