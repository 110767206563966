import React, { useState, useId } from 'react';

// Styles
import './styles.scss';

function FormSelect(props) {

	const htmlForId = useId();
	
	return (
		<div className="FormInput">
			{props.label && (
				<label htmlFor={htmlForId} className="FormInput-Label">{props.label}:</label>
			)}
			<select
				value={props.value}
				onChange={props.onChange}

				required={props.required}
				placeholder={props.placeholder ? props.placeholder : (props.required ? "Bitte eingeben" : "Optional")}
			
				disabled={props.disabled}
				
				type="text"
				className={`FormInput-Input${(!props.label) ? ' FormInput-Input--noLabel' : ''}`}
				id={htmlForId}
			>
				{props.options.map((option, index) => (
					<option
						key={index}
						value={option.value}
					>{option.label}</option>
				))}
			</select>
		</div>
	);
}

export default FormSelect;