import React, { useEffect } from 'react';
import FocusTrap from 'focus-trap-react';

// Styles
import './styles.scss';

function Modal(props) {

	useEffect(() => {
		if (props.isClosable) {
			const close = (e) => {
				if(e.keyCode === 27){
					props.closeAction()
				}
			}
			window.addEventListener('keydown', close)
			return () => window.removeEventListener('keydown', close)
		}
	}, [props])

	return (
		<FocusTrap>
			<div className={`Modal`}>
				<div className="Modal-Container">
					<div className="Modal-Container-Content">
						<span className="Modal-Container-Content-Title">{props.title}</span><br />
						{props.children}
					</div>
					<div className="Modal-Container-Actions">
						{props.actionButtons && (
							props.actionButtons.map((actionButton, index) => (
								<button key={index} className={`Modal-Container-Actions-ActionButton${(actionButton.type ? ` Modal-Container-Actions-ActionButton--${actionButton.type}` : '')}`} onClick={actionButton.action}>{actionButton.title}</button>
							))
						)}
						{props.isClosable && (
							<button className="Modal-Container-Actions-CloseButton" onClick={props.closeAction}>Schließen</button>
						)}
					</div>
				</div>
			</div>
		</FocusTrap>
	);
}

export default Modal;