import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {setAuthToken} from "../../utils/setAuthToken";

// Styles
import './styles.scss';

// Components
import Loader from '../../components/Loader';
import ContentBox from '../../components/ContentBox';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';

function ProcessStatusView(props) {
	
	const navigate = useHistory();

	return (
		<div className="Status-Content">
			<ContentBox>
				{props.status && (
					props.status === "error" ? (
						<>
							<span className="material-symbols-rounded">
								error
							</span>

							<h1>Ein Fehler ist aufgetreten.</h1>
							<p>Beim Ausführen des Prozesses ist ein Fehler aufgetreten. Bitte versuche es erneut.</p>

							<Button onClick={() => {
								navigate.push("/");
							}}>Zur Startseite</Button>
						</>
					) : (
						<>
							<span className="material-symbols-rounded">
								check_circle
							</span>

							<h1>Prozess erfolgreich abgeschlossen.</h1>

							<Button onClick={() => {
								navigate.push("/");
							}}>Zur Startseite</Button>
						</>
					)
				)}
			</ContentBox>
		</div>
	);
}

export default ProcessStatusView;