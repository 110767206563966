import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import LinkBox from '../../../components/LinkBox';
import Modal from '../../../components/Modal';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';
import FormInput from '../../../components/FormInput';

function DefaultProcessesArchivView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);
	const [archiveFolders, setArchiveFolders] = useState([]);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [archiveFolderNameValue, setArchiveFolderNameValue] = useState("");
	const [archiveFolderDescriptionValue, setArchiveFolderDescriptionValue] = useState("");
	const [archiveFolderNameError, setArchiveFolderNameError] = useState(false)

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/archive`).then((res) => {
			console.log(res.data)
			setArchiveFolders(res.data);
			setShowLoader(false);
		})
	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="archiv-1.jpeg"
					title="Dokumentenarchiv"
					text="Verwalten Sie Ihre Dokumente, Dateien in Ihrem zentralen SalesX-Archiv."

					hasCancelButton={false}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/")
					}}

					actionButtons={[
						{
							title: "Ordner anlegen",
							action: () => {
								setShowCreateModal(true);
							}
						}
					]}
				/>

				<ProcessSideContent>
					{archiveFolders.map((archiveFolder, i) =>
						<LinkBox
							key={i}
							link={`/default-processes/archiv/${archiveFolder.id}`}
							title={`#${archiveFolder.id} ${archiveFolder.folder_name}`}
							text={`${archiveFolder.folder_description !== null ? archiveFolder.folder_description : "./."}`}
						/>
					)}
				</ProcessSideContent>
			</Content>

			{showCreateModal && (
				<Modal
					title="Ordner anlegen"
					isClosable={true}
					closeAction={() => {
						setShowCreateModal(false);
					}}

					actionButtons={[
						{
							title: "Anlegen",
							action: () => {
								if (archiveFolderNameValue.trim().length === 0) {
									setArchiveFolderNameError(true);
									return;
								} else {
									setArchiveFolderNameError(false);
								}

								setShowLoader(true);

								axios.post(`${process.env.REACT_APP_API_URL}/api/archive`, {
									folder_name: archiveFolderNameValue,
									folder_description: archiveFolderDescriptionValue
								}).then((res) => {
									setShowLoader(false);
									if (res.data.status === "success") {
										setShowLoader(false);
										navigate.push("/status/success");
									} else {
										setShowLoader(false);
										navigate.push("/status/error");
									}
								})
							}
						}
					]}
				>
					<p>Gebe einen Namen für deinen neuen Ordner und eine Beschreibung (optional) an.</p>

					<FormInput
						label="Ordnername"
						type="text"
						required
						value={archiveFolderNameValue}
						onChange={(e) => {setArchiveFolderNameValue(e.target.value)}}
						error={archiveFolderNameError}
					/>
					<FormInput
						label="Beschreibung"
						type="text"
						value={archiveFolderDescriptionValue}
						onChange={(e) => {setArchiveFolderDescriptionValue(e.target.value)}}
					/>
				</Modal>
			)}
		</>
	);
}

export default DefaultProcessesArchivView;