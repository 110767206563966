import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../components/Loader';
import Content from '../../../../components/Content';
import ContentBox from '../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import FormInput from '../../../../components/FormInput';
import LinkBox from '../../../../components/LinkBox';
import FormTextarea from '../../../../components/FormTextarea';
import FormCheckbox from '../../../../components/FormCheckbox';

import { useStateWithCallbackLazy } from 'use-state-with-callback';

// Utils
import formatPrice from '../../../../utils/formatPrice';
import formatDate from '../../../../utils/formatDate';

function DefaultProcessesKundenEditView() {

	let navigate = useHistory();
	let { customerId } = useParams();
	const [showLoader, setShowLoader] = useState(true);

	const [customerNumberInput, setCustomerNumberInput] = useState("");
	const [companyNameInput, setCompanyNameInput] = useState("");
	const [firstnameInput, setFirstnameInput] = useState("");
	const [lastnameInput, setLastnameInput] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [phoneInput, setPhoneInput] = useState("");
	const [addressLine1Input, setAddressLine1Input] = useState("");
	const [addressLine2Input, setAddressLine2Input] = useState("");
	const [zipInput, setZipInput] = useState("");
	const [cityInput, setCityInput] = useState("");
	const [countryInput, setCountryInput] = useState("Deutschland");
	const [stateInput, setStateInput] = useState("");
	const [internalNoteInput, setInternalNoteInput] = useState("");

	const [customerBankAccounts, setCustomerBankAccounts] = useState([]);
	const [internalAccounts, setInternalAccounts] = useState([]);
	const [bills, setBills] = useState([]);
	
	const [customerNumberError, setCustomerNumberError] = useState(false);
	const [companyNameError, setCompanyNameError] = useState(false);
	const [firstnameError, setFirstnameError] = useState(false);
	const [lastnameError, setLastnameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [addressLine1Error, setAddressLine1Error] = useState(false);
	const [addressLine2Error, setAddressLine2Error] = useState(false);
	const [zipError, setZipError] = useState(false);
	const [cityError, setCityError] = useState(false);
	const [countryError, setCountryError] = useState(false);
	const [stateError, setStateError] = useState(false);

	const resetAllErrors = () => {
		setCustomerNumberError(false);
		setCompanyNameError(false);
		setFirstnameError(false);
		setLastnameError(false);
		setEmailError(false);
		setPhoneError(false);
		setAddressLine1Error(false);
		setAddressLine2Error(false);
		setZipError(false);
		setCityError(false);
		setCountryError(false);
		setStateError(false);
	}

	useEffect(() => {
		// Generate customer number
		axios.get(`${process.env.REACT_APP_API_URL}/api/customers/` + customerId).then((res) => {
			console.log(res.data)

			setShowLoader(false);

			setCustomerNumberInput(res.data.customer_number);
			setCompanyNameInput(res.data.company_name);
			setFirstnameInput(res.data.firstname);
			setLastnameInput(res.data.lastname);
			setEmailInput(res.data.email);
			setPhoneInput(res.data.phone);
			setAddressLine1Input(res.data.address_line_1);
			setAddressLine2Input(res.data.address_line_2);
			setZipInput(res.data.zip);
			setCityInput(res.data.city);
			setCountryInput(res.data.country);
			setStateInput(res.data.state);
			setInternalNoteInput(res.data.notes);

			setCustomerBankAccounts(res.data.banks);
			setInternalAccounts(res.data.internal_accounts);
			setBills(res.data.bills);
		})
	}, [])

	const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    } 

	const saveCustomer = () => {
		setShowLoader(true);
		axios.put(`${process.env.REACT_APP_API_URL}/api/customers/` + customerId, {
			company_name: companyNameInput,
			firstname: firstnameInput,
			lastname: lastnameInput,
			email: emailInput,
			phone: phoneInput,
			address_line_1: addressLine1Input,
			address_line_2: addressLine2Input,
			zip: zipInput,
			city: cityInput,
			state: stateInput,
			internal_note: internalNoteInput,
		}).then((res) => {
			setShowLoader(false);
			if (res.data.status === "success") {
				navigate.push("/status/success");
			} else {
				navigate.push("/status/error");
			}
		}).catch(() => {
			setShowLoader(false);
			navigate.push("/status/error");
		})
	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="customers-1.jpg"
					title="Kunde bearbeiten"
					text="Verwalten Sie Ihre Kunden, Adressen und Kommunikationsdaten."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/kunden");
					}}

					hasSubmitButton={true}
					submitButtonTitle="Kunde speichern"
					submitButtonAction={() => {
						saveCustomer()
					}}

					actionButtons={[
						{
							title: "Kunde löschen",
							action: () => {
								navigate.push("/default-processes/kunden/create");
							}
						},
						{
							title: "Bankkonto hinzufügen",
							action: () => {
								navigate.push("/default-processes/kunden/create");
							}
						},
						{
							title: "Internes Konto hinzufügen",
							action: () => {
								navigate.push("/default-processes/kunden/create");
							}
						},
					]}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Kundendaten"
					>
						<FormInput
							label="Kunden Nr."
							value={customerNumberInput}
							onChange={(e) => {
								setCustomerNumberInput(e.target.value);
							}}
							required
							error={customerNumberError}
							disabled
						/>
						<FormInput
							label="Firma"
							value={companyNameInput}
							onChange={(e) => {
								setCompanyNameInput(e.target.value);
							}}
							error={companyNameError}
						/>
						<FormInput
							label="Vorname(n)"
							value={firstnameInput}
							onChange={(e) => {
								setFirstnameInput(e.target.value);
							}}
							required
							error={firstnameError}
						/>
						<FormInput
							label="Nachname"
							value={lastnameInput}
							onChange={(e) => {
								setLastnameInput(e.target.value);
							}}
							required
							error={lastnameError}
						/>
					</ContentBox>

					<ContentBox
						title="Adressdaten"
					>
						<FormInput
							label="E-Mail"
							value={emailInput}
							onChange={(e) => {
								setEmailInput(e.target.value);
							}}
							error={emailError}
						/>


						<FormInput
							label="Telefon"
							value={phoneInput}
							onChange={(e) => {
								setPhoneInput(e.target.value);
							}}
							error={phoneError}
						/>

						<FormInput
							label="Adresszeile 1"
							value={addressLine1Input}
							onChange={(e) => {
								setAddressLine1Input(e.target.value);
							}}
							error={addressLine1Error}
						/>

						<FormInput
							label="Adresszeile 2"
							value={addressLine2Input}
							onChange={(e) => {
								setAddressLine2Input(e.target.value);
							}}
							error={addressLine2Error}
						/>

						<FormInput
							label="Postleitzahl"
							value={zipInput}
							onChange={(e) => {
								setZipInput(e.target.value);
							}}
							error={zipError}
						/>

						<FormInput
							label="Ort"
							value={cityInput}
							onChange={(e) => {
								setCityInput(e.target.value);
							}}
							error={cityError}
						/>

						<FormInput
							label="Bundesland"
							value={stateInput}
							onChange={(e) => {
								setStateInput(e.target.value);
							}}
							error={stateError}
						/>

						<FormInput
							label="Land"
							value={countryInput}
							onChange={(e) => {
								setCountryInput(e.target.value);
							}}
							required
							disabled
							error={countryError}
						/>
					</ContentBox>

					{customerBankAccounts.length > 0 && (
						<ContentBox
							title="Bank Konten"
						>
							<br />
							<br />
							{customerBankAccounts.map((bankAccount, index) => (
								<LinkBox
									key={index}
									link={`/default-processes/banken/${bankAccount.id}/edit`}
									title={`${bankAccount.bank_iban}`}
									text={`${bankAccount.bank_name}, ${bankAccount.bank_bic}`}
								/>
							))}
						</ContentBox>
					)}

					{internalAccounts.length > 0 && (
						<ContentBox
							title="Interne Konten"
						>
							<br />
							<br />
							{internalAccounts.map((internalAccount, index) => (
								<LinkBox
									key={index}
									link={`/default-processes/internal-accounts/${internalAccount.id}/edit`}
									title={`${internalAccount.account_number}; ${internalAccount.account_name}`}
									text={`${internalAccount.account_description} (${internalAccount.account_type}${internalAccount.parent_name && (`; Elem. in ${internalAccount.parent_name}`)})`}
								/>
							))}
						</ContentBox>
					)}

					{bills.length > 0 && (
						<ContentBox
							title="Rechnungen"
						>
							<br />
							<br />
							{bills.map((bill, index) => (
								<LinkBox
									key={index}
									link={`/default-processes/bills/${bill.id}/edit`}
									title={`
										${bill.bill_identifier}; ${
											(bill.bill_type === "outgoing_invoice") ? "Ausgehende Rechnung" : 
											(bill.bill_type === "incoming_invoice") ? "Eingehende Rechnung" :
											(bill.bill_type === "internal_transfer") ? "Interne Umbuchung" :
											(bill.bill_type === "cancellation") ? "Stornobuchung" :
											(bill.bill_type === "offer") ? "Angebot" :
											(bill.bill_type === "periodic") ? "Periodische Buchung (mtl.)" : "Unbekannte buchung"
										}
									`}
									text={`Erstellt: ${formatDate(bill.created_at)}`}
								/>
							))}
						</ContentBox>
					)}

					<ContentBox
						title="Sonstige Daten"
					>
						<FormTextarea
							label="Interne Notizen"
							value={internalNoteInput}
							onChange={(e) => {
								setInternalNoteInput(e.target.value);
							}}
						/>
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesKundenEditView;