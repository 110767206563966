import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Content from '../../../../components/Content';
import ContentBox from '../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import FormSelect from '../../../../components/FormSelect';
import FormCheckbox from '../../../../components/FormCheckbox';
import FormInput from '../../../../components/FormInput';
import FormTextarea from '../../../../components/FormTextarea';

function DefaultProcessesRechnungenEditView() {

	let { orderId } = useParams();
	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);

	const [editOrderCustomer, setEditOrderCustomer] = useState("");
	const [editOrderAddress, setEditOrderAddress] = useState("");

	useEffect(() => {
		setTimeout(() => {
			setShowLoader(false);
		}, 1500)
	})

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="orders-1.jpg"
					title={`Rechnung bearbeiten`}
					text="Verwalten Sie Ihre Rechnungen und erhalten Sie eine Übersicht."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/rechnungen/" + orderId);
					}}
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/default-processes/rechnungen")
					}}
				/>

				<ProcessSideContent>
					<ContentBox title="Details:">
						<table className="table-view">
							<tbody>
								<tr>
									<td className="table-view-title">
										Rechnungs-Nr.:
									</td>
									<td className="table-view-content">
										#{orderId}
									</td>
								</tr>
								<tr>
									<td className="table-view-title">
										Status:
									</td>
									<td className="table-view-content">
										Eingegangen
									</td>
								</tr>
							</tbody>
						</table>
						<FormTextarea
							minimal
							label="Adresse"
							value={editOrderAddress}
							onChange={(e) => {
								setEditOrderAddress(e.target.value);
							}}
							required
						/>
					</ContentBox>
					<ContentBox title="Positionen:">
						<table className="table-view table-view--left-aligned">
							<thead>
								<tr>
									<th className="table-view-title">Art.-Nr.</th>
									<th className="table-view-title">Bez.</th>
									<th className="table-view-title">Anzahl</th>
									<th className="table-view-title">St. Preis</th>
									<th className="table-view-title">Ges. Preis</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="table-view-content">
										1212
									</td>
									<td className="table-view-content">
										Test Produkt
									</td>
									<td className="table-view-content">
										1
									</td>
									<td className="table-view-content">
										12,99 €
									</td>
									<td className="table-view-content">
										12,99 €
									</td>
								</tr>
								<tr>
									<td className="table-view-content">
										1212
									</td>
									<td className="table-view-content">
										Test Produkt
									</td>
									<td className="table-view-content">
										1
									</td>
									<td className="table-view-content">
										12,99 €
									</td>
									<td className="table-view-content">
										12,99 €
									</td>
								</tr>
							</tbody>
						</table>
					</ContentBox>
					<ContentBox title="Rechnung:">
						<table className="table-view">
							<tbody>
								<tr>
									<td className="table-view-title">
										Gesamt:
									</td>
									<td className="table-view-content">
										25,98 €
									</td>
								</tr>
								<tr>
									<td className="table-view-title">
										Versandkosten:
									</td>
									<td className="table-view-content">
										2,90 €
									</td>
								</tr>
								<tr>
									<td className="table-view-title table-view-title--highlighted">
										In Rechnung gestellt:
									</td>
									<td className="table-view-content table-view-content--highlighted">
										28,88 €
									</td>
								</tr>
							</tbody>
						</table>
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesRechnungenEditView;