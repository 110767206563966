import { format, formatRelative } from 'date-fns'

const formatDate = (dateString, exact = false) => {
	dateString = dateString.replace(/-/g, "/");

	var system_date = new Date(dateString);
    var user_date = new Date();

	if (exact) {
		return "am " + system_date.toLocaleString("de-DE");
	}

    var diff = Math.floor((user_date - system_date) / 1000);
    if (diff <= 1) {return "Gerade eben";}
    if (diff < 20) {return "Vor " + diff + " Sekunden";}
    if (diff < 40) {return "Vor einer halben Minute";}
    if (diff < 60) {return "Vor weniger als einer Minute";}
    if (diff <= 90) {return "Vor einer Minute";}
    if (diff <= 3540) {return "Vor " + Math.round(diff / 60) + " Minuten";}
    if (diff <= 5400) {return "Vor 1 Stunde";}
    if (diff <= 86400) {return "Vor " + Math.round(diff / 3600) + " Stunden";}
    if (diff <= 129600) {return "Gestern";}
    if (diff < 604800) {return "Vor " +Math.round(diff / 86400) + " Tagen";}
    if (diff <= 777600) {return "Vor 1 Woche";}
    return "am " + system_date.toLocaleString("de-DE");
}

export default formatDate;