import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

function Content(props) {

	const [navigationExists, setNavigationExists] = useState(false);

	useEffect(() => {
		if (document.querySelector('.Navigation')) {
			setNavigationExists(true);
		} else {
			setNavigationExists(false);
		}
	}, [])

	return (
		<div className={`Content ${!navigationExists ? 'Content--no-navigation' : ''}`}>{props.children}</div>
	);
}

export default Content;