import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../components/Loader';
import Content from '../../../../components/Content';
import ContentBox from '../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';
import FormTextarea from '../../../../components/FormTextarea';
import FormCheckbox from '../../../../components/FormCheckbox';

import { useStateWithCallbackLazy } from 'use-state-with-callback';

// Utils
import formatPrice from '../../../../utils/formatPrice';

function DefaultProcessesKundenCreateView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(true);

	const [customerNumberInput, setCustomerNumberInput] = useState("");
	const [companyNameInput, setCompanyNameInput] = useState("");
	const [firstnameInput, setFirstnameInput] = useState("");
	const [lastnameInput, setLastnameInput] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [phoneInput, setPhoneInput] = useState("");
	const [addressLine1Input, setAddressLine1Input] = useState("");
	const [addressLine2Input, setAddressLine2Input] = useState("");
	const [zipInput, setZipInput] = useState("");
	const [cityInput, setCityInput] = useState("");
	const [countryInput, setCountryInput] = useState("Deutschland");
	const [stateInput, setStateInput] = useState("");
	const [internalNoteInput, setInternalNoteInput] = useState("");
	const [addBankInformation, setAddBankInformation] = useState(false);
	const [bankNameInput, setBankNameInput] = useState("");
	const [bankRecipientInput, setBankRecipientInput] = useState("");
	const [bankCodeInput, setBankCodeInput] = useState("");
	const [bankIbanInput, setBankIbanInput] = useState("");
	const [bankBicInput, setBankBicInput] = useState("");
	const [createInternalAccount, setCreateInternalAccount] = useState(false);
	const [internalAccountsList, setInternalAccountsList] = useState([]);
	const [internalAccountParent, setInternalAccountParent] = useState(14);
	const [openingBalance, setBpeningBalance] = useState("0,00 €");
	
	const [customerNumberError, setCustomerNumberError] = useState(false);
	const [companyNameError, setCompanyNameError] = useState(false);
	const [firstnameError, setFirstnameError] = useState(false);
	const [lastnameError, setLastnameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [addressLine1Error, setAddressLine1Error] = useState(false);
	const [addressLine2Error, setAddressLine2Error] = useState(false);
	const [zipError, setZipError] = useState(false);
	const [cityError, setCityError] = useState(false);
	const [countryError, setCountryError] = useState(false);
	const [stateError, setStateError] = useState(false);
	const [internalNoteError, setInternalNoteError] = useState(false);
	const [bankNameError, setBankNameError] = useState(false);
	const [bankRecipientError, setBankRecipientError] = useState(false);
	const [bankCodeError, setBankCodeError] = useState(false);
	const [bankIbanError, setBankIbanError] = useState(false);
	const [bankBicError, setBankBicError] = useState(false);

	const [finishCreateBillProcess, setFinishCreateBillProcess] = useState(false);
	const [archiveFileId, setArchiveFileId] = useState(0);

	const resetAllErrors = () => {
		setCustomerNumberError(false);
		setCompanyNameError(false);
		setFirstnameError(false);
		setLastnameError(false);
		setEmailError(false);
		setPhoneError(false);
		setAddressLine1Error(false);
		setAddressLine2Error(false);
		setZipError(false);
		setCityError(false);
		setCountryError(false);
		setStateError(false);
		setInternalNoteError(false);
		setBankNameError(false);
		setBankRecipientError(false);
		setBankCodeError(false);
		setBankIbanError(false);
		setBankBicError(false);
	}

	const reverseFormatNumber = (val, locale) => {
        let group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
        let decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
        let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
        reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
        return Number.isNaN(reversedVal)?0:reversedVal;
    }

	useEffect(() => {
		// Generate customer number
		axios.get(`${process.env.REACT_APP_API_URL}/api/customers/generateCustomerNumber`).then((res) => {
			if (res.data.data.customer_number) {
				setShowLoader(false);
				setCustomerNumberInput(res.data.data.customer_number);
				resetAllErrors();
			}
		})

		// Check if there is a local storage item
		if (localStorage.getItem("customer")) {

			let customer = JSON.parse(localStorage.getItem("customer"));

			// If customer.nameInput are two words, then split them and set them to firstname and lastname
			if (customer.nameInput.split(" ").length > 1) {
				setFirstnameInput(customer.nameInput.split(" ")[0]);
				setLastnameInput(customer.nameInput.split(" ")[1]);
			} else {
				setCompanyNameInput(customer.nameInput);
			}

			setAddressLine1Input(customer.addressInput);
			setAddressLine2Input(customer.address2Input);
			setZipInput(customer.postcodeInput);
			setCityInput(customer.cityInput);

			setFinishCreateBillProcess(true);
			setArchiveFileId(customer.archiveFileId);

		}

	}, [])

	const loadInternalAccounts = () => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/internal-accounts`).then((res) => {

			let internalAccountsArray = [];

			(res.data).map((internalAccount) => {
				if (internalAccount.account_number !== "-1") {
					internalAccountsArray.push({
						value: internalAccount.id,
						label: internalAccount.account_name
					})
				}
			})

			setInternalAccountsList(internalAccountsArray);

			setShowLoader(false);
		})
	}

	const validateIban = (iban) => {
		setShowLoader(true);
		setBankNameInput("");
		setBankCodeInput("");
		setBankBicInput("");
		axios.get(`https://openiban.com/validate/${iban}?getBIC=true`).then((res) => {
			setShowLoader(false);
			if (res.data) {
				setBankIbanError(!res.data.valid)

				if (res.data.valid) {
					setBankNameInput(res.data.bankData.name)
					setBankCodeInput(res.data.bankData.bankCode)
					setBankBicInput(res.data.bankData.bic)
				}
			} else {
				setBankIbanError(false)
			}
		}).catch((err) => {
			setShowLoader(false);
			setBankIbanError(false)
		})
		return;
	}

	const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    } 

	const createCustomer = () => {

		resetAllErrors();
		setShowLoader(true);

		/*
			REQUIRED FIELDS:
			customerNumberInput
			firstnameInput
			lastnameInput
		*/		
	
		// Check if all required fields are filled
		if (customerNumberInput.trim() === "" || firstnameInput.trim() === "" || lastnameInput.trim() === "") {
			setCustomerNumberError(customerNumberInput.trim() === "");
			setFirstnameError(firstnameInput.trim() === "");
			setLastnameError(lastnameInput.trim() === "");
			setShowLoader(false);
			return;
		}

		// If addBankInformation is true, check if all required fields are filled
		if (addBankInformation) {
			if (bankRecipientInput.trim() === "" || bankIbanInput.trim() === "") {
				setBankRecipientError(bankRecipientInput.trim() === "");
				setBankIbanError(bankIbanInput.trim() === "");
				setShowLoader(false);
				return;
			}
		}

		// Check if email is valid. But notice: E-Mails can contain umlauts like äöü
		if (emailInput.trim() !== "") {
			if (!validateEmail(emailInput)) {
				setEmailError(true);
				setShowLoader(false);
				return;
			}
		}

		/*
			Possible errors:
			customerNumberError
			companyNameError
			firstnameError
			lastnameError
			emailError
			phoneError
			addressLine1Error
			addressLine2Error
			zipError
			cityError
			countryError
			stateError
			internalNoteError
			bankNameError
			bankRecipientError
			bankCodeError
			bankIbanError
			bankBicError
		*/

		// If all error-States are false, create customer
		if (!customerNumberError && !companyNameError && !firstnameError && !lastnameError && !emailError && !phoneError && !addressLine1Error && !addressLine2Error && !zipError && !cityError && !countryError && !stateError && !internalNoteError && !bankNameError && !bankRecipientError && !bankCodeError && !bankIbanError && !bankBicError) {


			// Unformat Opening Balance
			let openingBlanceRaw = openingBalance;
			openingBlanceRaw = openingBlanceRaw.replace(/\./g, "");
			openingBlanceRaw = reverseFormatNumber(openingBlanceRaw, "de");
			openingBlanceRaw = parseFloat(openingBlanceRaw);	

			// Create customer
			axios.post(`${process.env.REACT_APP_API_URL}/api/customers`, {
				customer_number: customerNumberInput,
				company_name: companyNameInput,
				firstname: firstnameInput,
				lastname: lastnameInput,
				email: emailInput,
				phone: phoneInput,
				address_line_1: addressLine1Input,
				address_line_2: addressLine2Input,
				zip: zipInput,
				city: cityInput,
				country: countryInput,
				state: stateInput,
				internal_note: internalNoteInput,
				bank_name: bankNameInput,
				bank_recipient: bankRecipientInput,
				bank_code: bankCodeInput,
				bank_iban: bankIbanInput,
				bank_bic: bankBicInput,
				add_bank_information: addBankInformation,
				create_internal_account: createInternalAccount,
				internal_account_id: internalAccountParent,
				opening_balance: openingBlanceRaw
			}).then((res) => {
				setShowLoader(false);
				if (res.data.status === "success") {

					if (finishCreateBillProcess) {
						axios.get(`${process.env.REACT_APP_API_URL}/api/archive/5/${archiveFileId}`, {
							responseType: 'blob'
						})
						.then((res) => {
							setShowLoader(false);
	
							const url = window.URL.createObjectURL(new Blob([res.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', "download.pdf");
							document.body.appendChild(link);
							link.click();
						})

						localStorage.removeItem("customer");
					} else {
						setShowLoader(false);
					}

					navigate.push("/status/success")
				} else {
					setShowLoader(false);
					navigate.push("/status/error")
				}
			}).catch((err) => {
				setShowLoader(false);
				navigate.push("/status/error")
			})

		} else {
			setShowLoader(false);
			return;
		}

	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="customers-1.jpg"
					title="Kunde anlegen"
					text="Verwalten Sie Ihre Kunden, Adressen und Kommunikationsdaten."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/kunden");
					}}

					hasSubmitButton={true}
					submitButtonTitle="Kunde anlegen"
					submitButtonAction={() => {
						createCustomer()
					}}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Kundendaten"
					>
						<FormInput
							label="Kunden Nr."
							value={customerNumberInput}
							onChange={(e) => {
								setCustomerNumberInput(e.target.value);
							}}
							required
							error={customerNumberError}
							disabled
						/>
						<FormInput
							label="Firma"
							value={companyNameInput}
							onChange={(e) => {
								setCompanyNameInput(e.target.value);
							}}
							error={companyNameError}
						/>
						<FormInput
							label="Vorname(n)"
							value={firstnameInput}
							onChange={(e) => {
								setFirstnameInput(e.target.value);
							}}
							required
							error={firstnameError}
						/>
						<FormInput
							label="Nachname"
							value={lastnameInput}
							onChange={(e) => {
								setLastnameInput(e.target.value);
							}}
							required
							error={lastnameError}
						/>
					</ContentBox>

					<ContentBox
						title="Adressdaten"
					>
						<FormInput
							label="E-Mail"
							value={emailInput}
							onChange={(e) => {
								setEmailInput(e.target.value);
							}}
							error={emailError}
						/>


						<FormInput
							label="Telefon"
							value={phoneInput}
							onChange={(e) => {
								setPhoneInput(e.target.value);
							}}
							error={phoneError}
						/>

						<FormInput
							label="Adresszeile 1"
							value={addressLine1Input}
							onChange={(e) => {
								setAddressLine1Input(e.target.value);
							}}
							error={addressLine1Error}
						/>

						<FormInput
							label="Adresszeile 2"
							value={addressLine2Input}
							onChange={(e) => {
								setAddressLine2Input(e.target.value);
							}}
							error={addressLine2Error}
						/>

						<FormInput
							label="Postleitzahl"
							value={zipInput}
							onChange={(e) => {
								setZipInput(e.target.value);
							}}
							error={zipError}
						/>

						<FormInput
							label="Ort"
							value={cityInput}
							onChange={(e) => {
								setCityInput(e.target.value);
							}}
							error={cityError}
						/>

						<FormInput
							label="Bundesland"
							value={stateInput}
							onChange={(e) => {
								setStateInput(e.target.value);
							}}
							error={stateError}
						/>

						<FormInput
							label="Land"
							value={countryInput}
							onChange={(e) => {
								setCountryInput(e.target.value);
							}}
							required
							disabled
							error={countryError}
						/>
					</ContentBox>

					<ContentBox
						title="Bank Daten"
					>
						<FormCheckbox
							label="Bankdaten"
							checked={addBankInformation}
							onChange={(e) => {

								let bankReceipientName = "";

								if (companyNameInput.trim() !== "") {
									bankReceipientName = companyNameInput;
								} else {
									if (firstnameInput.trim() !== "") {
										bankReceipientName = firstnameInput + " " + lastnameInput;
									} else {
										bankReceipientName = lastnameInput;
									}
								}

								setBankRecipientInput(bankReceipientName.trim());

								setAddBankInformation(e);
							}}
							text="Bankdaten hinzufügen"
						/>

						{addBankInformation && (
							<>
								<FormInput
									label="Empfänger-Name"
									value={bankRecipientInput}
									onChange={(e) => {
										setBankRecipientInput((e.target.value).toUpperCase());
									}}
									required
									error={bankRecipientError}
								/>
								<FormInput
									label="IBAN"
									value={bankIbanInput}
									onChange={(e) => {
										setBankIbanInput((e.target.value).toUpperCase());
									}}
									onBlur={(e) => {
										validateIban(e.target.value);
									}}
									required
									error={bankIbanError}
								/>
								<FormInput
									label="BLZ"
									value={bankCodeInput}
									onChange={(e) => {
										setBankCodeInput(e.target.value);
									}}
									error={bankCodeError}
									disabled
								/>
								<FormInput
									label="BIC"
									value={bankBicInput}
									onChange={(e) => {
										setBankBicInput(e.target.value);
									}}
									error={bankBicError}
									disabled
								/>
								<FormInput
									label="Bank-Name"
									value={bankNameInput}
									onChange={(e) => {
										setBankNameInput(e.target.value);
									}}
									disabled
									error={bankNameError}
								/>
							</>
						)}
					</ContentBox>

					<ContentBox
						title="Internes Konto"
					>
						<FormCheckbox
							label="Internes Konto"
							checked={createInternalAccount}
							onChange={(e) => {
								setCreateInternalAccount(e);
								loadInternalAccounts();
							}}
							text="Anlegen"
						/>
						{createInternalAccount && (
							<>
								<FormSelect
									label="Ober-Konto"
									value={internalAccountParent}
									onChange={(e) => {
										setInternalAccountParent(e.target.value);
									}}
									options={internalAccountsList}
								/>
								<FormInput
									label="Eröffnungssaldo"
									value={openingBalance}
									onChange={(e) => {
										setBpeningBalance(e.target.value);
									}}
									required
									formatprice
									error={bankRecipientError}
								/>
							</>
						)}
					</ContentBox>

					<ContentBox
						title="Sonstige Daten"
					>
						<FormTextarea
							label="Interne Notizen"
							value={internalNoteInput}
							onChange={(e) => {
								setInternalNoteInput(e.target.value);
							}}
						/>
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesKundenCreateView;